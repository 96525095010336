import React from 'react';
import styles from './SideMenuPatan.module.css'; // Импортируем стили как модуль

import { useSelector, useDispatch } from 'react-redux';
// import { dopPovrezhdenia } from '../store/index';
import { patan } from '../store/index';

function SideMenuPatan() {
    const gb = useSelector((state) => state.pat.gb);
    const saharDiab1 = useSelector((state) => state.pat.saharDiab1);
    const saharDiab2 = useSelector((state) => state.pat.saharDiab2);
    const vich = useSelector((state) => state.pat.vich);
    const covid = useSelector((state) => state.pat.covid);
    const gepatB = useSelector((state) => state.pat.gepatB);
    const gepatC = useSelector((state) => state.pat.gepatC);
    const sifilis = useSelector((state) => state.pat.sifilis);
    const gripp = useSelector((state) => state.pat.gripp);
    const tuberc = useSelector((state) => state.pat.tuberc);
    // const ushibRanaNoogr = useSelector((state) => state.dopPovrezhden.ushibRanaNoogr);

    const dispatchFunction = useDispatch();
    function changeStatusHandler(type) {
        if (type === 'gb') {
            dispatchFunction(patan.gb());
        } else if (type === 'saharDiab1') {
            dispatchFunction(patan.saharDiab1());
        } else if (type === 'saharDiab2') {
            dispatchFunction(patan.saharDiab2());
        } else if (type === 'vich') {
            dispatchFunction(patan.vich());
        } else if (type === 'covid') {
            dispatchFunction(patan.covid());
        } else if (type === 'gepatB') {
            dispatchFunction(patan.gepatB());
        } else if (type === 'gepatC') {
            dispatchFunction(patan.gepatC());
        } else if (type === 'sifilis') {
            dispatchFunction(patan.sifilis());
        } else if (type === 'gripp') {
            dispatchFunction(patan.gripp());
        } else if (type === 'tuberc') {
            dispatchFunction(patan.tuberc());
        } 
        // else if (type === 'ushibRanaNoogr') {
        //     dispatchFunction(dopPovrezhdenia.ushibRanaNoogr());
        // }
    }

    return (
        
        <div className={styles["side-menu-wrapper"]}>
            <div className={styles["side-menu"]}>
                <ul> Дополнительно добавить патологию внутренних органов:  
                    <li
                        className={`${tuberc ? styles.linkActive : ''}`}
                        onClick={() => changeStatusHandler('tuberc')}
                    >
                        Туберкулез
                    </li>  
                    <li
                        className={`${sifilis ? styles.linkActive : ''}`}
                        onClick={() => changeStatusHandler('sifilis')}
                    >
                        Сифилис
                    </li>  
                    <li
                        className={`${gepatB ? styles.linkActive : ''}`}
                        onClick={() => changeStatusHandler('gepatB')}
                    >
                        Вирусный гепатит B
                    </li>
                    <li
                        className={`${gepatC ? styles.linkActive : ''}`}
                        onClick={() => changeStatusHandler('gepatC')}
                    >
                        Вирусный гепатит C
                    </li>  
                    <li
                        className={`${vich ? styles.linkActive : ''}`}
                        onClick={() => changeStatusHandler('vich')}
                    >
                        ВИЧ-инфекция, стадия (период) СПИД
                    </li>
                    <li
                        className={`${gripp ? styles.linkActive : ''}`}
                        onClick={() => changeStatusHandler('gripp')}
                    >
                        Грипп
                    </li> 
                    <li
                        className={`${covid ? styles.linkActive : ''}`}
                        onClick={() => changeStatusHandler('covid')}
                    >
                        COVID-19
                    </li> 
                    <li
                        className={`${saharDiab1 ? styles.linkActive : ''}`}
                        onClick={() => changeStatusHandler('saharDiab1')}
                    >
                        Сахарный диабет 1 типа
                    </li>         
                    <li
                        className={`${saharDiab2 ? styles.linkActive : ''}`}
                        onClick={() => changeStatusHandler('saharDiab2')}
                    >
                        Сахарный диабет 2 типа
                    </li>     
                    <li
                        className={`${gb ? styles.linkActive : ''}`}
                        onClick={() => changeStatusHandler('gb')}
                    >
                        ГБ высокой степени и стадии (3 степень и 2-3 стадии)
                    </li>                  
                   
                    {/* <li
                        className={`${ushibRanaNoogr ? styles.linkActive : ''}`}
                        onClick={() => changeStatusHandler('ushibRanaNoogr')}
                    >
                        Ушибленная рана (неограниченная поверхность)
                    </li> */}
                </ul>
            </div>
        </div>
    );
}

export default SideMenuPatan;

