import { useSelector } from 'react-redux';

//Импорт компонентов пат изменений:
import GbPochki from '../../patan/gb/GbPochki';
import SaharDiab1Pochki from '../../patan/saharDiab1/SaharDiab1Pochki';
import SaharDiab2Pochki from '../../patan/saharDiab2/SaharDiab2Pochki';
import CovidPochki from '../../patan/covid/CovidPochki';
import GepatBPochki from '../../patan/gepatB/GepatBPochki';
import GepatСPochki from '../../patan/gepatС/GepatСPochki';
import GrippPochki from '../../patan/gripp/GrippPochki';
import TubercPochki from '../../patan/tuberc/TubercPochki';

function PatanPochki() {

    //Получаем из хранилища состояния пат изменений:
    const gb = useSelector((state) => state.pat.gb);
    const saharDiab1 = useSelector((state) => state.pat.saharDiab1);
    const saharDiab2 = useSelector((state) => state.pat.saharDiab2);
    const covid = useSelector((state) => state.pat.covid);
    const gepatB = useSelector((state) => state.pat.gepatB);
    const gepatC = useSelector((state) => state.pat.gepatC);
    const gripp = useSelector((state) => state.pat.gripp);
    const tuberc = useSelector((state) => state.pat.tuberc);
    // const ushibRanaNoogr = useSelector((state) => state.dopPovrezhden.ushibRanaNoogr);
    

    return (
        <>
            {/* Туберкулез: */}
            {tuberc && <><TubercPochki/>
            <br></br></>}
            {/* ГБ: */}
            {gb && <><GbPochki/>
            <br></br></>}
            {/* Сахар диабет 1: */}
            {saharDiab1 && <><SaharDiab1Pochki/>
            <br></br></>}
            {/* Сахар диабет 2: */}
            {saharDiab2 && <><SaharDiab2Pochki/>
            <br></br></>}
            {/* Covid-19: */}
            {covid && <><CovidPochki/>
            <br></br></>}
            {/* Гепатит B: */}
            {gepatB && <><GepatBPochki/>
            <br></br></>}
            {/* Гепатит C: */}
            {gepatC && <><GepatСPochki/>
            <br></br></>}
            {/* Гепатит C: */}
            {gripp && <><GrippPochki/>
            <br></br></>}
            {/* Ссадины: */}
            {/* {ssadiny  && <><SsadinyDiagnoz/>
            <br></br></>} */}
        </>
    );
}

export default PatanPochki;