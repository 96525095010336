import { useSelector } from 'react-redux';

//Импорт компонентов пат изменений:
import GbDiagnoz from '../../patan/gb/GbDiagnoz';
import SaharDiab1Diagnoz from '../../patan/saharDiab1/SaharDiab1Diagnoz';
import SaharDiab2Diagnoz from '../../patan/saharDiab2/SaharDiab2Diagnoz';
import VichDiagnoz from '../../patan/vich/VichDiagnoz';
import CovidDiagnoz from '../../patan/covid/CovidDiagnoz';
import GepatBDiagnoz from '../../patan/gepatB/GepatBDiagnoz';
import GepatСDiagnoz from '../../patan/gepatС/GepatСDiagnoz';
import SifilisDiagnoz from '../../patan/sifilis/SifilisDiagnoz';
import GrippDiagnoz from '../../patan/gripp/GrippDiagnoz';
import TubercDiagnoz from '../../patan/tuberc/TubercDiagnoz';

function PatanDiagnoz() {

    //Получаем из хранилища состояния пат изменений:
    const gb = useSelector((state) => state.pat.gb);
    const saharDiab1 = useSelector((state) => state.pat.saharDiab1);
    const saharDiab2 = useSelector((state) => state.pat.saharDiab2);
    const vich = useSelector((state) => state.pat.vich);
    const covid = useSelector((state) => state.pat.covid);
    const gepatB = useSelector((state) => state.pat.gepatB);
    const gepatC = useSelector((state) => state.pat.gepatC);
    const sifilis = useSelector((state) => state.pat.sifilis);
    const gripp = useSelector((state) => state.pat.gripp);
    const tuberc = useSelector((state) => state.pat.tuberc);
    
    // const bampPerelom = useSelector((state) => state.dopPovrezhden.bampPerelom);

    return (
        <>
            {/* Туберкулез: */}
            {tuberc && <><TubercDiagnoz/>
            <br></br></>}
            {/* ГБ: */}
            {gb && <><GbDiagnoz/>
            <br></br></>}
            {/* Сахарный диабет 1: */}
            {saharDiab1 && <><SaharDiab1Diagnoz/>
            <br></br></>}
            {/* Сахарный диабет 2: */}
            {saharDiab2 && <><SaharDiab2Diagnoz/>
            <br></br></>}
            {/* ВИЧ: */}
            {vich && <><VichDiagnoz/>
            <br></br></>}
            {/* Covid-19: */}
            {covid && <><CovidDiagnoz/>
            <br></br></>}
            {/* Гепатит B: */}
            {gepatB && <><GepatBDiagnoz/>
            <br></br></>}
            {/* Гепатит C: */}
            {gepatC && <><GepatСDiagnoz/>
            <br></br></>}
            {/* Сифилис: */}
            {sifilis && <><SifilisDiagnoz/>
            <br></br></>}
            {/* Грипп: */}
            {gripp && <><GrippDiagnoz/>
            <br></br></>}
            {/* Ссадины: */}
            {/* {ssadiny  && <><SsadinyDiagnoz/>
            <br></br></>} */}
        </>
    );
}

export default PatanDiagnoz;