function SaharDiab2Serdce(){
    return (
        <p id="MsoBodyTextIndent" className="green">При СД 2 типа сердце часто увеличено в размерах, особенно за счёт гипертрофии левого желудочка, что связано с 
        артериальной гипертензией и атеросклеротическим поражением коронарных артерий. Миокард плотный и утолщённый, может быть тусклым из-за фиброза и кардиосклероза. 
        Часто обнаруживаются мелкие рубцы, следы инфарктов миокарда или ишемических изменений из-за атеросклероза. Генерализованный атеросклероз крупных артерий, особенно 
        коронарных, мозговых и периферических сосудов. Стенки артерий утолщены, на них видны атеросклеротические бляшки, что нередко приводит к ишемической болезни сердца, 
        инсультам и периферической артериальной недостаточности. Диабетическая макроангиопатия преобладает над микроангиопатией, что отличает диабет 2 типа от диабета 1 типа.
        </p>
    )
}
export default SaharDiab2Serdce