function TubercMochPuz(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При туберкулезном поражении мочевого пузыря (туберкулезный цистит) изменения могут быть как локальными, так и 
        распространенными в зависимости от стадии и активности заболевания. Чаще всего туберкулез мочевого пузыря развивается как вторичное поражение вследствие туберкулезного 
        воспаления почек и верхних мочевых путей, откуда инфекция распространяется нисходящим путем. Макроскопическая картина мочевого пузыря при туберкулезе:
1. Утолщение и деформация стенок:
Стенки мочевого пузыря становятся утолщенными и плотными, что особенно заметно в области шейки пузыря и задней стенки.
При хронизации процесса утолщение становится выраженным, что может приводить к деформации стенок и уменьшению объема пузыря, вплоть до образования так называемого "микроцистиса" (маленького мочевого пузыря).
2. Туберкулезные язвы:
На слизистой оболочке пузыря часто выявляются изъязвления (язвы) разной глубины. Они выглядят как неровные, с подрытыми краями дефекты, покрытые серовато-белым или желтоватым налетом.
Язвы могут сливаться, образуя обширные зоны поражения слизистой оболочки, которые при прогрессировании процесса становятся более глубокими, разрушая подлежащие слои стенки пузыря.
3. Казеозный некроз:
В некоторых случаях в области язв или туберкулезных гранулем может обнаруживаться казеозный некроз. Макроскопически некротические очаги имеют вид беловатых, творожистых масс, которые могут выходить в полость пузыря или оставаться в слоях стенки.
Эти очаги казеоза нередко окружаются фиброзной тканью, что создает плотную оболочку вокруг некротического участка.
4. Туберкулезные гранулемы:
В стенках пузыря, особенно в подслизистом слое, образуются мелкие узелковые образования (туберкулезные гранулемы). Они видны как серовато-белые мелкие узелки, плотно прилегающие к слизистой оболочке или глубже, в мышечном слое.
Гранулемы часто окружены воспалительной реакцией, что придает пораженной стенке плотность и увеличенную толщину.
5. Фиброз и склероз:
При длительном течении заболевания стенка мочевого пузыря подвергается значительным фиброзным изменениям, приводящим к рубцовой деформации. Это ограничивает его растяжимость и объем, что вызывает прогрессирующую дисфункцию органа.
В зонах бывших язв и гранулем формируется плотная рубцовая ткань, что еще больше утолщает стенки и нарушает их нормальную анатомическую структуру.
6. Гиперемия и отек:
При активном воспалении наблюдается гиперемия слизистой оболочки и отек тканей пузыря. Слизистая приобретает красный оттенок, покрыта серозным или гнойным налетом, особенно вокруг язвенных дефектов.
В случае присоединения вторичной инфекции или длительного воспаления могут быть мелкие кровоизлияния и гнойные выделения, видимые на поверхности слизистой оболочки.
        </p> 
                      
    )
}
export default TubercMochPuz