function SifilisLungs(){
    return (
        <p id="MsoBodyTextIndent" className="green">
       При сифилисе возможна сифилитическая пневмония: легкие плотные, с участками фиброза и воспаления. При прогрессировании сифилиса в легких может наблюдаться 
       формирование гуммозных узлов с некрозом в центре. Пример макроскопической картины легких с сифилитической пневмонией (с участками инфильтрации, казеозного некроза и 
       выраженным фиброзом; поражение легочной ткани сопровождается изменениями бронхов и увеличением регионарных лимфатических узлов, что характерно для длительного хронического 
       течения инфекции): Легкие увеличены в объеме, плотные на ощупь, с очагами уплотнения. Плевра утолщена, местами с серо-желтыми наложениями. На поверхности легких могут быть заметны участки сероватых или желтоватых инфильтратов.
При разрезе легочная ткань имеет неоднородный вид: чередование плотных участков серо-белого и серо-желтого цвета с более мягкими зонами, сохранными участками легочной ткани. Плотные участки, как правило, расположены субплеврально и могут сливаться, формируя более обширные инфильтраты.
Инфильтраты характеризуются грануляционной тканью с участками казеозного некроза. Эти зоны окружены плотной фиброзной тканью, которая придает легкому бугристую структуру на разрезе. В области поражения легочная ткань маловоздушна или вовсе лишена воздуха.
Бронхи умеренно расширены, их слизистая утолщена и может быть покрыта серозно-гнойным или слизистым экссудатом. Лимфатические узлы бронхопульмональной группы увеличены, плотные, могут иметь желтоватый оттенок на разрезе из-за инфильтрации грануляционной тканью и возможного казеозного распада.
Сосуды легочной ткани могут быть склерозированы, с признаками эндоваскулита, что характерно для сифилитического поражения. Иногда отмечаются участки геморрагии в паренхиме легких, связанные с повреждением сосудов.   
        </p>
    )
}
export default SifilisLungs