function TubercPochki(){
    return (
        <p id="MsoBodyTextIndent" className="green">При поражении почек туберкулезом (туберкулезный нефрит) макроскопические изменения зависят от стадии болезни, 
        распространенности процесса и формы поражения. Основные признаки включают образование узелков, участков казеозного некроза, каверн и рубцовых изменений:
        1. Начальная стадия:
        Изолированные узелки: туберкулезный процесс начинается с небольших очагов казеозного некроза, которые располагаются в корковом слое почки. В этой стадии почка может иметь незначительные изменения, и пораженные участки выглядят как небольшие узелки или очаги серовато-желтого цвета.
        Размер и структура узелков: очаги обычно имеют плотную консистенцию, могут быть окружены участками гиперемии. Узелки, которые могут сливаться при прогрессировании, видны на разрезе органа.
        2. Прогрессирование с образованием каверн:
        Формирование каверн: по мере развития туберкулезного процесса в почечной ткани возникают полости (каверны), образованные вследствие распада казеозных масс и некротизированных тканей.
        Вид каверн: каверны имеют неправильную форму, их стенки могут быть неровными, с обрывками некротической ткани. Они заполнены желтовато-серой творожистой массой, характерной для казеозного некроза.
        Расположение: каверны чаще встречаются в корковом слое, но по мере прогрессирования процесса могут распространяться и на мозговой слой почки.
        3. Множественные казеозные очаги и распад ткани:
        Распространение очагов: по мере прогрессирования туберкулеза казеозные очаги могут распространяться по всей ткани почки, образуя многочисленные некротические зоны. Почка может приобретать пеструю картину на разрезе с множеством желтоватых, творожистых участков.
        Некротические массы: казеозные очаги придают почке неоднородный вид. Некротизированные зоны мягкие, крошковатые, окружены более плотной, воспаленной тканью.
        4. Фиброз и рубцевание:
        Рубцовые изменения: при хроническом течении процесса в почках развивается фиброз, и пораженные участки частично замещаются соединительной тканью.
        Уменьшение почки: в результате рубцевания почка может уменьшаться в размере и становиться плотной, на ее поверхности заметны рубцовые впадины и деформации.
        Рубцы на поверхности: в местах излеченных очагов могут образовываться плотные фиброзные участки, придавая органу бугристую поверхность.
        5. Поражение чашечно-лоханочной системы (туберкулезный пиелит):
        Изменения в чашечках и лоханках: при переходе туберкулезного процесса в чашечно-лоханочную систему на слизистой оболочке появляются язвы, казеозные массы и грануляции.
        Увеличение и деформация лоханки: при обширном поражении чашечки и лоханка могут быть увеличены, их слизистая становится шероховатой и покрыта некротическими массами, что может приводить к нарушению оттока мочи.
        6. Кальцификация:
        Петрификация казеозных масс: в хронической стадии процесса казеозные массы в почечной ткани могут петрифицироваться, превращаясь в плотные, кальцинированные участки, которые видны на разрезе как беловатые или серовато-белые включения.
        </p>
    )
}
export default TubercPochki