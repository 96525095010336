function GepatBLab(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            Для вирусного гепатита B: при <u>гистологическом исследовании:</u> Печень: дегенерация и некроз гепатоцитов: клетки печени часто содержат баллонные (вакулярные) 
            изменения, свидетельствующие о повреждении. Мостовидные некрозы: 
            некрозы в печени могут соединять портальные тракты или переходить на соседние дольки. Воспалительная инфильтрация: в портальных трактах и вблизи центральной 
            вены часто наблюдаются скопления лимфоцитов, макрофагов и иногда плазматических клеток. "Матовые" гепатоциты: инфицированные клетки приобретают характерный 
            мутноватый вид, их цитоплазма становится гранулированной, а ядра могут быть увеличены. Фиброз: при хроническом гепатите наблюдается разрастание соединительной 
            ткани вокруг портальных трактов, иногда с формированием септ, что предшествует развитию цирроза. Селезенка: гиперплазия лимфоидной ткани: увеличение и активное 
            образование лимфоидных фолликулов свидетельствуют о гиперактивности иммунной системы. Склероз в ткани селезенки при длительном течении болезни, связанный с 
            повышенной нагрузкой на орган в ответ на инфекцию. Почки: мезангиопролиферативный гломерулонефрит: отмечается увеличение количества клеток и матрикса в мезангии 
            клубочков, что сопровождается нарушением фильтрационной функции. Отложения иммунных комплексов: в базальной мембране клубочков могут накапливаться иммунные 
            комплексы (антиген HBsAg с антителами), что вызывает воспаление и повреждение почечной ткани. Лимфоузлы: гиперплазия лимфоидной ткани: в лимфатических узлах 
            отмечается увеличение количества лимфоцитов и плазматических клеток в ответ на хроническое воспаление и антигенную стимуляцию вирусом. Поджелудочная железа 
            (при тяжелом течении заболевания): в редких случаях наблюдается мелкоочаговая инфильтрация лимфоцитами, что может свидетельствовать о вовлечении железы в 
            воспалительный процесс при значительном иммунном ответе организма. Эти микроскопические изменения зависят от стадии заболевания и активности вируса гепатита B 
            в организме. <u>Иммуногистохимическое исследование:</u> Выявление антигена HBsAg (поверхностного антигена вируса гепатита B) в тканях печени, что подтверждает наличие 
вирусной инфекции. ИГХ может также помочь выявить HBcAg (ядерный антиген вируса гепатита B) в гепатоцитах, что является дополнительным маркером вирусной активности. <u>
    Серологические исследования крови:</u> Анализ на наличие антител к вирусу гепатита B (Anti-HBc, Anti-HBs) и антигена HBsAg. Эти исследования могут подтвердить факт 
инфицирования и различить фазу острой и хронической инфекции. Выявление HBeAg и антител к нему (Anti-HBe) для оценки степени активности вируса.
HBsAg (поверхностный антиген вируса гепатита B):
•	Положительный результат: Указывает на наличие инфекции. Если сохраняется более 6 месяцев, это свидетельствует о хронической инфекции.
•	Отрицательный результат: Означает отсутствие активной инфекции или выздоровление после острой инфекции.
Anti-HBs (антитела к поверхностному антигену вируса гепатита B):
•	Положительный результат: Указывает на иммунитет к вирусу гепатита B вследствие вакцинации или перенесенной и завершившейся инфекции.
•	Отрицательный результат: Может означать отсутствие иммунитета, что требует дополнительной проверки других маркеров для уточнения состояния.
Anti-HBc (антитела к ядерному антигену вируса гепатита B):
•	IgM Anti-HBc:
- Положительный результат: Указывает на острую или недавнюю инфекцию.
- Отрицательный результат: Исключает недавнюю острую инфекцию.
•	IgG Anti-HBc:
- Положительный результат: Признак текущей или перенесенной инфекции. Если при этом HBsAg отрицательный и Anti-HBs положительный, это свидетельствует о выздоровлении и наличии иммунитета.
- Отрицательный результат: Отсутствие предшествующей инфекции.
HBeAg (антиген вируса гепатита B):
•	Положительный результат: Указывает на высокую вирусную нагрузку и активное размножение вируса, что свидетельствует о высокой степени заразности пациента.
•	Отрицательный результат: Может означать снижение активности вируса или мутацию вируса, при которой HBeAg не определяется.
Anti-HBe (антитела к антигену HBe):
•	Положительный результат: Указывает на низкую активность вируса и начало процесса выздоровления или переход в хроническую стадию с низкой вирусной активностью.
•	Отрицательный результат: Может свидетельствовать о высокой активности вируса, особенно если HBeAg положительный.
Комбинации результатов и их интерпретация:
1.	HBsAg (+), Anti-HBs (-), IgM Anti-HBc (+), HBeAg (+), Anti-HBe (-): Острая фаза инфекции с высокой активностью и заразностью.
2.	HBsAg (+), Anti-HBs (-), IgG Anti-HBc (+), HBeAg (+), Anti-HBe (-): Хроническая инфекция с высокой активностью вируса.
3.	HBsAg (+), Anti-HBs (-), IgG Anti-HBc (+), HBeAg (-), Anti-HBe (+): Хроническая инфекция с низкой активностью вируса, возможно, переход в стадию ремиссии.
4.	HBsAg (-), Anti-HBs (+), IgG Anti-HBc (+), HBeAg (-), Anti-HBe (+): Перенесенная инфекция и наличие иммунитета (выздоровление).
5.	HBsAg (-), Anti-HBs (+), IgG Anti-HBc (-): Иммунитет вследствие вакцинации.
6.	HBsAg (-), Anti-HBs (-), IgG Anti-HBc (+): Возможна перенесенная инфекция с сероконверсией, либо ложноположительный результат. Требуются дополнительные исследования.
Дополнительные замечания:
•	Положительный результат Anti-HBc без других маркеров может свидетельствовать о латентной инфекции или ошибке в анализе, что требует проведения ПЦР для выявления 
вирусной ДНК.
•	Если HBeAg отрицательный, но вирусная ДНК определяется (по ПЦР), это может указывать на мутацию вируса с невозможностью продукции HBeAg. <u>Полимеразная цепная реакция 
    (ПЦР):</u> Проведение ПЦР для обнаружения ДНК вируса гепатита B в образцах крови или ткани печени, что позволяет установить факт наличия вирусной инфекции на 
    молекулярном уровне. Количественная ПЦР помогает оценить вирусную нагрузку и активность инфекции. <u>Биохимический анализ крови:</u> Оценка уровня билирубина, АЛТ, АСТ и 
    щелочной фосфатазы. Повышенные уровни этих ферментов могут свидетельствовать о повреждении печени.
        </p>
    )
}
export default GepatBLab