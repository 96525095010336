function GrippPochki(){
    return (
        <p id="MsoBodyTextIndent" className="green">
        Гриппозная инфекция может вызывать разнообразные изменения в почках, включая тубулярные и интерстициальные повреждения, а также, в редких случаях, кровоизлияния.
Макроскопическая картина oстрого тубулоинтерстициального нефрита (ОТИН):
Размер почек: почки могут быть увеличены в размере из-за отёка.
Цвет и поверхность: поверхность почек может быть гладкой, но иногда наблюдается лёгкая гиперемия. Увеличение кровенаполнения может придавать почкам более насыщенный цвет.
Кровоизлияния: кровоизлияния в почках при гриппе встречаются нечасто, но могут наблюдаться в тяжёлых случаях, особенно при наличии коагулопатий или шока. Они могут проявляться 
как точечные или более обширные участки геморрагий в корковом и мозговом веществе почек.
        </p>                
    )
}
export default GrippPochki