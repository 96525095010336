function VichVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            При жизни имелась ВИЧ-инфекция, стадия клиническая (период) IV (СПИД: ВИЧ-ассоциированная иммунодефицитная энцефалопатия, пневмоцистная пневмония, 
            цитомегаловирусный колит, токсоплазмоз головного мозга), на что указывают макро- и микро- скопические 
        изменения этих внутренних органов, наличие антител к специфическим белкам ВИЧ в образцах крови, выявление возбудителей (Pneumocystis jirovecii, цитомегаловируса и 
        токсоплазмы) в соответствующих органах при их бактериологическом исследовании. Указанное заболевание в причинной связи с наступлением смерти - не состоит.
        </p>
    )
}
export default VichVyvody