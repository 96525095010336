function GepatBPechen(){
    return (
        <p id="MsoBodyTextIndent" className="green">При вирусном гепатите B может наблюдаться увеличение печени (гепатомегалия), часто с округлыми краями. (При молниеносной 
        форме - печень уменьшена в размере, масса снижена до 1000 – 1100 г, цвет её желтый или желтый с красным крапом). Цвет печени может варьировать от красно-коричневого 
        до желтоватого, что связано с жировой дистрофией гепатоцитов. Плотность печени может быть увеличена из-за воспаления и отека. В случаях хронического течения может 
        наблюдаться фиброз или цирроз с наличием узлов регенерации + может образоваться асцит. Возможны признаки воспаления желчного пузыря или застоя желчи (холестаза) 
        при нарушении функции печени. Желчный пузырь может быть увеличен из-за нарушения выделения желчи. При развитии портальной гипертензии могут наблюдаться варикозное 
        расширение вен пищевода и желудка, что представляет риск для кровотечений. На слизистой оболочке желудка могут быть видны петехии и мелкие кровоизлияния, связанные 
        с гипертензией и нарушением свертываемости.
        </p>
    )
}
export default GepatBPechen