function VichLab(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            Для ВИЧ клинической стадии (периода) IV (СПИД), характерно: при <u>гистологическом исследовании:</u> 
             1. Легкие:
Наличие пенистой экссудации и обширных альвеолярных инфильтратов, характерных для пневмоцистной пневмонии.
Гранулематозное воспаление при микобактериальных инфекциях (например, туберкулезе) с гигантскими клетками Лангханса.
Грибковые элементы (например, Candida или Aspergillus), визуализируемые с помощью специальных красок (PAS или Гомори метенамин серебро).
2. Лимфатические узлы:
Гиперплазия лимфоидных фолликулов, характерная для ранних стадий ВИЧ-инфекции.
Поздние стадии часто сопровождаются истощением лимфоидной ткани и заменой ее жировой тканью, атрофией фолликулов.
При вирусной нагрузке обнаруживается синусовый гистиоцитоз и наличие вирусных антигенов в клетках (например, в фолликулярных дендритных клетках).
3. Селезенка:
Реактивная гиперплазия фолликулов с увеличением количества плазматических клеток.
Обширные инфильтраты макрофагов и лимфоцитов.
Воспалительные гранулемы при наличии микобактериозов или других оппортунистических инфекций.
4. Печень:
Очаги воспаления и стеатоза (жировая дистрофия гепатоцитов).
При наличии оппортунистических инфекций — гранулемы и микрогранулемы, особенно при инфекциях, таких как Mycobacterium avium complex (MAC).
Воспалительные инфильтраты из лимфоцитов и макрофагов в портальных трактах.
5. Желудочно-кишечный тракт:
Изъязвления с некрозом эпителия, ассоциированные с цитомегаловирусной инфекцией, визуализируемые с помощью специального окрашивания.
Обширные лимфоцитарные и плазмоцитарные инфильтраты в слизистой оболочке.
Наличие кандидозных элементов в эпителии, обнаруживаемых при PAS-окраске.
6. Головной мозг:
Признаки ВИЧ-энцефалопатии с вакуолизацией и демиелинизацией белого вещества.
Микроглиальные узелки и многоядерные гигантские клетки, что характерно для вирусного поражения ЦНС.
При токсоплазмозе — некротические очаги с воспалительными инфильтратами, содержащими трофозоиты и цисты токсоплазмы, выявляемые с помощью гистохимического окрашивания.
7. Кожа и слизистые оболочки:
Множественные узелковые поражения, вызванные саркомой Капоши, включающие веретенообразные клетки и пролиферацию эндотелия сосудов.
Изъязвления и псевдомембранозные налеты при кандидозе.
Вирусные включения в клетках эпителия, характерные для герпесвирусной инфекции. <u>Серологическое исследование:</u> ИФА (иммуноферментный анализ) для выявления антител к ВИЧ в образцах крови или других тканях. Это стандартное исследование 
        для выявления ВИЧ-инфекции. Обычно используется несколько тест-систем для подтверждения положительного результата.
Иммуноблоттинг (иммуноблот), проводимый после положительного результата ИФА, чтобы подтвердить наличие антител к специфическим белкам ВИЧ. <u>ПЦР (полимеразная цепная 
реакция):</u> ПЦР для определения РНК ВИЧ или ДНК провируса. Этот метод позволяет подтвердить наличие вируса в организме даже при отсутствии антител (например, на ранних 
стадиях инфицирования) или при выраженном иммунодефиците. ПЦР также позволяет оценить вирусную нагрузку, что может подтвердить стадию и активность инфекции. <u>
Имуногистохимическое исследование:</u> используется для выявления антигенов ВИЧ в тканях (например, с помощью антител против белков ВИЧ). Имуногистохимия может 
подтвердить наличие вируса в тканях и особенно полезна для определения поражения головного мозга и лимфатических узлов. (Исследование лимфоидной ткани на наличие вирусных 
антигенов: Фолликулярные дендритные клетки и другие клетки иммунной системы часто содержат ВИЧ-антигены. Их обнаружение может помочь подтвердить ВИЧ-инфекцию, особенно 
если лабораторные тесты на антитела недостаточно информативны). <u>Идентификация оппортунистических инфекций:</u> посевы и микроскопия для выявления специфических 
возбудителей инфекций (например, Mycobacterium tuberculosis, Pneumocystis jirovecii, цитомегаловируса, токсоплазмы). Окраска тканей специальными методами (например, PAS, 
серебрение) для обнаружения грибковых элементов и других патогенов.
        </p>
    )
}
export default VichLab