function PloskKostjOgrVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="blue"> - вдавленный террасовидный перелом центральной части чешуи затылочной кости, о чем свидетельствует наличие здесь участка вдавления с расположенными в нем один над другим в виде ступенек осколками.
        <br></br> Видом воздействия, причинившим данное повреждение, был удар, на это указывает:
       -одностороннее расположение места воздействия
       -центростремительный вектор
       -вдавленный характер перелома 
        Преимущественное действие травмирующего предмета по отношению к повреждаемому участку кости имело прямой угол, о чем свидетельствует архитектоника вдавленного перелома. В то же время, некоторая составляющая компонента удара имела вектор действия в направлении распространения трещины (слева – направо), так как механизмом образования этой трещины явилась деформация от усилий на разрыв, об этом же свидетельствует и более глубокий характер вдавления на стороне образования трещины.
         Повреждение образовалось не менее чем от одного удара, о чем говорит наличие одного очага вдавленного перелома
         Объектом, причинившим данное повреждение, является твердый предмет, имеющий ограниченную поверхность, на что указывает вдавленный характер перелома.
         Травмирующая поверхность твердого предмета имела шестиугольную форму размером 2,5х2,4см., в центре которого находился округлый выступ, размером не менее 1,2х0,9см., что подтверждается формами и размером наружного края вдавленного перелома и осколком округлой формы, являющимся дном воронкообразного вдавления.
         Установить прижизненность перелома с помощью лишь макроскопического метода исследования не представляется возможным.      
        </p>
    )
}
export default PloskKostjOgrVyvody