function ColotoRezVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="blue"> 
            - Колото-резаное ранение груди слева, проникающее в левую плевральную полость  и средостение, с повреждением III и IV ребер, межреберных мышц между ними, верхней доли левого легкого и сердца, кровоизлиянием в левую плевральную полость (700 г свертков крови и 200 мл жидкой крови) и в полость сердечной сумки (100 г свертков крови и 20 мл жидкой крови), с раной в области левой большой грудной мышцы (входное отверстие). Направление раневого канала спереди – назад, снизу-вверх и слева направо, длина его около 7 см.
<br></br>Рана в области левой большой грудной мышцы на трупе N является колото-резанной, что подтверждается её формой, ровными неосадненными краями, наличием одного острого и одного «П»-образного конца, преобладанием глубины раневого канала над длиной раны.
Колото-резаное ранение груди слева причинено колюще-режущим предметом, каким мог быть клинок ножа. Судя по длине раны и глубине раневого канала максимальная ширина погрузившейся части клинка составила не более 2,5 см, а длина около 7 см. Судя по форме концов раны, клинок имел острое лезвие и обушок. Обушок имел прямоугольную форму и ширину около 0,2 см, что подтверждается  формой и размерами П-образного конца раны. В момент нанесения ранения обушок был ориентирован на 4,5 часов условного циферблата,  что подтверждается взаимным расположением П-образного и острого концов раны. Направление  удара  было спереди кзади, снизу-вверх и слева направо (при условии правильного вертикального положения тела),  на что указывает направление раневого канала. Ранение причинено в результате одного удара,  о чем свидетельствует наличие одной раны и одного раневого канала.
Колото-резаное ранение груди слева носит прижизненный характер, на что указывают кровоизлияния в левую плевральную полость, в полость сердечной сумки и в мягкие ткани по ходу раневого канала. Данные гистологического исследования участков раневого канала, с учетом данных представленных на экспертизу медицинских документов, позволяет высказаться о возможности его причинения в интервал времени от 21.25 до 22.25 13 мая 2018 года.
Причиной смерти N явилось колото-резаное ранение груди слева, проникающее в левую плевральную полость  и средостение, с повреждением III и IV ребер, межреберных мышц между ними, верхней доли левого легкого и сердца, с раной в области левой большой грудной мышцы (входное отверстие), сопровождавшееся развитием острой кровопотери. Указанное повреждение было вредом, опасным для жизни человека и относится к ТЯЖКОМУ вреду здоровья.
            </p>                 
    )
}
export default ColotoRezVyvody