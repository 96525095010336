function CovidLab(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            Для COVID-19: при <u>гистологическом исследовании:</u> 
            1.	Лёгкие: диффузное альвеолярное повреждение (DAD): основной гистологический признак COVID-19, который включает повреждение альвеолярного эпителия, отёк 
            межальвеолярных перегородок и наличие гиалиновых мембран. Лимфоцитарная инфильтрация: наличие лимфоцитарных инфильтратов в интерстиции, что указывает на 
            воспалительный процесс. Микротромбозы: наличие мелких тромбов в капиллярах и мелких сосудах лёгких. Признаки фиброзирования: в случаях длительного течения 
            болезни может отмечаться фиброз интерстициальной ткани.
2.	Сердце: миокардит: наличие воспалительных инфильтратов, преимущественно лимфоцитарных, между мышечными волокнами. Дистрофические изменения кардиомиоцитов: отёк клеток, 
выраженные признаки ишемии и некроза отдельных участков. Микротромбы: небольшие тромбозы в капиллярах сердечной ткани.
3.	Почки: острое повреждение почечных канальцев: дистрофия и некроз эпителия канальцев. Воспалительные инфильтраты: иногда можно наблюдать лимфоцитарные и мононуклеарные 
инфильтраты в интерстиции. Микротромбозы: признаки тромбов в клубочковых капиллярах и мелких сосудах почек.
4.	Печень: жировая дистрофия гепатоцитов: особенно характерна для пациентов с ожирением или сопутствующими заболеваниями печени. Воспалительная инфильтрация: скопления 
лимфоцитов в портальных трактах. Микроангиопатия: поражение мелких сосудов, тромбозы в синусоидах.
5.	Головной мозг: микрогеморрагии и микроинфаркты: маленькие участки кровоизлияний и ишемических повреждений. Отёк мозга: заметное расширение межклеточного пространства. 
Глиальные реакции: активация микроглии и астроцитов в ответ на повреждение. <u>Вирусологическое и молекулярно-биологическое исследование:</u> ПЦР-тестирование (полимеразная 
цепная реакция), проводимое на образцах тканей лёгких, носоглотки или других участков, чтобы подтвердить присутствие вирусной РНК. Иммунохимические методы для выявления 
антигенов вируса. <u>Иммуногистохимическое исследование:</u> Применение методов иммуногистохимии для обнаружения антигенов SARS-CoV-2 в тканях, что позволяет визуализировать 
местонахождение вирусных частиц. <u>Серологическое исследование:</u> Анализ на наличие антител (IgG, IgM) в крови. 
Антитела IgM:
появляются в крови на ранних стадиях инфекции, обычно через 5–7 дней после заражения.
Их наличие указывает на острое или недавнее инфицирование.
IgM достигают пика через 2–3 недели после появления симптомов и начинают снижаться по мере выздоровления, постепенно исчезая через несколько недель или месяцев.
Антитела IgG:
появляются позже, обычно через 2–3 недели после начала инфекции.
Сохраняются в организме дольше и могут присутствовать в крови в течение нескольких месяцев или даже лет после перенесённой инфекции.
Наличие IgG свидетельствует о перенесённой инфекции и может указывать на формирование иммунного ответа.
Показатели для интерпретации:
Положительный результат на IgM: свидетельствует о текущей или недавней инфекции.
Положительный результат на IgG и отрицательный на IgM: указывает на перенесённую инфекцию и возможное наличие иммунитета.
Отрицательный результат на IgM и IgG: отсутствие признаков текущей или перенесённой инфекции (возможна серонегативная стадия или отсутствие контакта с вирусом).
Положительный результат на IgG и IgM одновременно: свидетельствует о периоде острой инфекции с активной выработкой антител. <u>Бактериологическое исследование:</u> 
Исключение или подтверждение сопутствующих бактериальных инфекций, которые могут вызвать или осложнить течение COVID-19.
        </p>
    )
}
export default CovidLab