function TubercPechen(){
    return (
        <p id="MsoBodyTextIndent" className="green">При туберкулезном поражении печени можно наблюдать различные макроскопические изменения в зависимости от формы заболевания, 
        стадии, длительности течения и путей распространения микобактерий:
        1. Милиарный туберкулез печени:
        Внешний вид: поверхность печени на вид гладкая или слегка бугристая.
        Размеры: печень часто увеличена, мягкая на ощупь.
        Мелкие очаги: на разрезе по всей ткани печени видны многочисленные мелкие желтовато-белые гранулемы, диаметром от 1 до 2 мм. Эти очаги расположены как по периферии, так и глубоко в паренхиме, и напоминают мелкие "просовидные" узелки.
        Характер гранулем: гранулемы представляют собой скопления эпителиоидных и лимфоцитарных клеток вокруг центрального казеозного некроза; иногда в центре можно увидеть микроскопические казеозные участки.
        2. Очаговый (фокальный) туберкулез печени:
        Характерные очаги: на поверхности печени и в паренхиме обнаруживаются отдельные туберкулезные гранулемы, более крупные и менее многочисленные по сравнению с милиарной формой. Размер этих очагов может варьироваться от нескольких миллиметров до 1 см.
        Казеозный некроз: центральная часть очагов часто представлена казеозным некрозом, окруженным слоем эпителиоидных клеток и лимфоцитов, иногда с гигантскими клетками Лангханса.
        Расположение: обычно такие очаги распределены неоднородно, могут быть сконцентрированы в определенных зонах или рассеяны.
        3. Туберкулезный абсцесс печени:
        Крупные полости: в ткани печени формируются участки казеозного некроза, которые могут сливаться, образуя полости (абсцессы), окруженные гранулематозной и фиброзной тканью.
        Капсула: вокруг таких абсцессов образуется плотная фиброзная капсула, отделяющая пораженные участки от здоровой ткани.
        Содержимое: внутри полости — желтовато-белая, творожистая масса казеозного некроза.
        4. Туберкулезный цирроз печени (цирротическая форма):
        Фиброзные изменения: печень деформирована и уплотнена за счет выраженного фиброза, что сопровождается изменением ее архитектоники и образованием спаек.
        Доли и сегменты: структура печени разделена фиброзными тяжами на узлы или неправильные участки, что придает органу цирротический вид.
        Гранулемы: в тканях печени могут присутствовать старые туберкулезные гранулемы, инкапсулированные и окруженные фиброзной тканью.
        </p>
    )
}
export default TubercPechen