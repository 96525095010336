function VyvodyUshibRanaOgr(){
    return (
        <p id="MsoBodyTextIndent" className="blue">- Ушибленная рана затылочной области справа.
<br></br> Рана затылочной области справа является ушибленной,  о чем  свидетельствуют неровные,  осаднённые,  кровоподтечные края, наличие разрывов кожи на концах раны, и наличие в глубине раны тканевых перемычек.    
<br></br> Рана  причинена тупым твердым предметом с ограниченной травмирующей поверхностью,  на что указывают С-образная форма раны,  наличие узкого осаднения по ее краям, повторяющего форму раны с четкими наружными границами.
<br></br> Травмирующая  поверхность  тупого предмета имела либо округлую форму, диаметром около 2,5 см, либо овальную форму, длиной не более 2,8 см и шириной около 2,5 см, о чем свидетельствует форма и размеры повреждения.
<br></br> Местом приложения травмирующей силы была правая половина затылочной области, что подтверждается локализацией повреждения.
<br></br> Травмирующая сила действовала справа-налево,  сверху-вниз  и несколько сзади-кпереди (при условии правильного вертикального положения тела),  о чем свидетельствуют локализация раны, наибольшая глубина повреждения  у  верхнего края,  скошенность верхней и и подрытость нижней стенки раны,  наличие, локализация и характер отслоенного, осаднённого лоскута кожи.
<br></br> Рана образовалась в результате удара,  на что указывают одностороннее  расположение  места  приложения травмирующей силы и ее центростремительное направление, а также ушибленный характер повреждения.
<br></br> Повреждение причинено в результате одного удара,  что подтверждается наличием одной раны с узким осаднением по краям,  овальной формой и небольшими размерами повреждения, наличием очагового кровоизлияния в подлежащих тканях,  а также отсутствием других повреждений  в этой области.
<br></br> Рана причинена прижизненно, о чем свидетельствуют кровоподтечность её краев и наличие кровоизлияния в подлежащих мягких тканях.
<br></br> Ушибленная рана правой половины затылочной области у гражданина(ки) N, не является опасным для жизни повреждением, не влечет за собой  расстройство здоровья или стойкую утрату трудоспособности, т.е. являются незначительным и скоропреходящим и в связи с этим не квалифицируются как вред здоровью. В прямой причинной связи со смертью гражданина(ки) N это повреждение не состоит. 
</p>)
}
export default VyvodyUshibRanaOgr