import { useSelector } from 'react-redux';

//Импорт компонентов пат изменений:
import VichSpleen from '../../patan/vich/VichSpleen';
import GepatBSpleen from '../../patan/gepatB/GepatBSpleen';
import GepatCSpleen from '../../patan/gepatС/GepatCSpleen';
import SifilisSpleen from '../../patan/sifilis/SifilisSpleen';
import GrippSpleen from '../../patan/gripp/GrippSpleen';
import TubercSpleen from '../../patan/tuberc/TubercSpleen';

function PatanSpleen() {

    //Получаем из хранилища состояния пат изменений:
    const vich = useSelector((state) => state.pat.vich);
    const gepatB = useSelector((state) => state.pat.gepatB);
    const gepatC = useSelector((state) => state.pat.gepatC);
    const sifilis = useSelector((state) => state.pat.sifilis);
    const gripp = useSelector((state) => state.pat.gripp);
    const tuberc = useSelector((state) => state.pat.tuberc);
    // const ushibRanaNoogr = useSelector((state) => state.dopPovrezhden.ushibRanaNoogr);
    

    return (
        <>
            {/* Туберкулез: */}
            {tuberc && <><TubercSpleen/>
            <br></br></>}
            {/* ВИЧ: */}
            {vich && <><VichSpleen/>
            <br></br></>}
            {/* Гепатит B: */}
            {gepatB && <><GepatBSpleen/>
            <br></br></>}
            {/* Гепатит C: */}
            {gepatC && <><GepatCSpleen/>
            <br></br></>}
            {/* Сифилис: */}
            {sifilis && <><SifilisSpleen/>
            <br></br></>}
            {/* Грипп: */}
            {gripp && <><GrippSpleen/>
            <br></br></>}
            {/* Ссадины: */}
            {/* {ssadiny  && <><SsadinyDiagnoz/>
            <br></br></>} */}
        </>
    );
}

export default PatanSpleen;