function TubercLungs(){
    return (
       <>
        <p id="MsoBodyTextIndent" className="green">
       При туберкулезе в легких обнаруживаются туберкулезные очаги различной степени зрелости — от свежих очагов казеозного некроза (беловатые, творожистые массы) до старых 
       фиброзных изменений. Возможны каверны (полости) — характерные для запущенных случаев полости в легочной ткани, образовавшиеся в результате распада ткани. В 
       бронхопульмональной и медиастинальной областях могут иметься увеличенные лимфатические узлы с казеозным некрозом. Нередко обнаруживаются плевральные спайки и утолщения. 
       Может быть плеврит с отложением фибрина или выпот (гнойный или серозный плевральный выпот).</p>
       <p id="MsoBodyTextIndent" className="green">
       Первичный туберкулезный комплекс: формируется в результате первичного заражения и включает три компонента: 1) первичный очаг в легком (очаг Гона — это первичный 
       туберкулезный очаг, который возникает при первичном инфицировании Mycobacterium tuberculosis), 2) лимфангит в виде цепочки туберкулезных бугорков по ходу лимфатического 
       сосуда, идущего к корню легкого и 3) поражение регионарных лимфатических узлов (лимфаденит) в виде казеозного некроза в прикорневых и бифуркационных лимфатических узлах. 
       Макроскопическое описание очага Гона:
Локализация: чаще всего обнаруживается в верхних сегментах нижней доли или нижних сегментах верхней доли легкого, что связано с особенностями кровообращения и вентиляции этих 
участков.
Размер: варьирует от нескольких миллиметров до 1-2 см в диаметре.
Внешний вид: очаг имеет плотную структуру и серовато-белый цвет. Центральная часть может выглядеть казеозной — с творожистым, сухим материалом, свидетельствующим о некрозе ткани.
Консистенция: обычно плотная, в центре может быть рыхлый казеозный материал.
Окружающая ткань: окружена зоной воспалительной инфильтрации и, по мере прогрессирования, может быть окружена зоной фиброза.
С течением времени очаг Гона может обызвествляться (кальцифицироваться) и оставаться в легком как свидетельство перенесенного первичного инфицирования.</p>
<p id="MsoBodyTextIndent" className="green">
Диссеминированный туберкулез легких:
<br></br>
- Очаги Симона — туберкулезные очаги в легком, которые возникают у лиц, перенесших первичную инфекцию туберкулезом. Они представляют собой реактивацию старой инфекции и 
чаще всего наблюдаются у взрослых, у которых первичный туберкулезный процесс был локализован и излечен в детстве.
Макроскопическое описание очага Симона: Локализация: очаги Симона преимущественно обнаруживаются в верхушках легких (верхние сегменты верхних долей). Это связано с тем, что в этих 
участках хуже кровоснабжение и вентиляция, что создает условия для повторной активации микобактерий. Внешний вид: очаги обычно имеют вид плотных, серовато-белых или желтоватых 
узелков. Они могут быть единичными или множественными. Размер: размеры могут варьировать от нескольких миллиметров до нескольких сантиметров.
Консистенция: плотные и часто окружены капсулой. В случае кальцификации они приобретают более твердую консистенцию и видны как плотные участки на рентгенограмме.
Содержимое: в центре очага может наблюдаться казеозный некроз (творожистое вещество), окруженный фиброзной тканью.
Очаги Симона являются важным макроскопическим проявлением при вторичном туберкулезе и служат подтверждением того, что инфекция в прошлом уже присутствовала в организме и 
реактивировалась при благоприятных для микобактерий условиях. Макроскопическое различие очага Гона и очага Симона заключается главным образом в их расположении (Очаг Гона: 
обычно обнаруживается в средних или нижних долях легких, так как это связано с местом первичного инфицирования при вдыхании микобактерий. Очаг Симона: Локализуется 
преимущественно в верхушках легких (верхние сегменты верхних долей), так как это вторичные очаги, возникшие вследствие реактивации старой инфекции). К тому же, очаг Гона - 
одиночное образование, а очаги Симонова - как правило, множественные плотные узелки.
<br></br>
- Очаги Абрикосова - небольшие плотные узелки или инфильтраты, которые могут быть обнаружены как при макроскопическом, так и при микроскопическом исследовании легочной ткани, могут 
встречаться множественно, располагаясь в различных частях легких, и часто являются признаком гематогенного распространения туберкулеза. В основе очагов лежат специфические 
гранулемы с казеозным некрозом в центре, окруженные эпителиоидными клетками и лимфоцитами. В некоторых случаях можно обнаружить гигантские клетки Лангханса. Эти очаги также 
возникают вследствие гематогенной диссеминации микобактерий туберкулеза. При заживлении очагов Абрикосова возникают петрификаты — ашофф-пулевские очаги, далее, из которых, при 
обострении процесса, могут возникать ацинозные и лобулярные очаги казеозной пневмонии, которые в дальнейшем инкапсулируются и петрифицируются (фиброзно-очаговый туберкулез), либо 
могут возникать очаги Ассмана—Редекера, для которых характерно значительное преобладание перифокального серозного воспаления над относительно небольшим 
участком казеозного некроза (инфильтративный туберкулез). Особой формой инфильтративного туберкулеза является лобит, при котором перифокальное воспаление захватывает всю долю.
Далее инфильтративный туберкулез может эволюционировать в туберкулему (округлый или овальный очаг казеозного некроза (имеющий серовато-желтый или желтовато-белый цвет с 
характерной плотной, крошащейся консистенцией, в отличие от более мягкой ткани вокруг), размерами от нескольких миллиметров до нескольких сантиметров (чаще 2-5 см в диаметре), 
окруженный плотной капсулой из фиброзной ткани).
<br></br>
- Милиарный туберкулез — это форма диссеминированного туберкулеза, при которой происходит массовое распространение микобактерий туберкулеза по всему организму через кровоток. 
При милиарном туберкулезе легкие имеют характерные макроскопические изменения, которые проявляются в виде множества мелких, зернистых очагов воспаления, распространяющихся по 
всей легочной ткани (Легкие утолщены и могут быть увеличены в объеме.
При вскрытии легкие имеют бледный цвет, а структура ткани становится рыхлой и измененной.
На поверхности легких или в их глубине можно наблюдать множество мелких очагов, напоминающих по виду зерна проса. Эти очаги могут быть диаметром от 1 до 2 мм.
Очаги представляют собой небольшие (до 2 мм в диаметре) плотные участки серовато-белого цвета, которые пронизывают всю легочную ткань.
Очаги могут быть как на поверхности легких, так и в их глубине, распространяясь по всей легочной паренхиме.
Некоторые очаги могут сливаться, образуя более крупные участки поражения.
Легочная ткань становится более плотной, часто с признаками отека и гиперемии в окружающих тканях.
На разрезе ткани видны множественные очаги казеозного некроза (характерное для туберкулезной инфекции состояние, когда ткани подвергаются разрушению и становятся мягкими и 
сероватыми). Плевра может быть поражена, при этом могут быть признаки плеврита. В таком случае на поверхности легких наблюдаются признаки отека и утолщения плевры.
В некоторых случаях части очагов могут кальцифицироваться (образование кальция в пораженных участках), что свидетельствует о старении процесса).
<br></br>
- Острый кавернозный диссеминированный туберкулез – при котором на фоне множества мелких очагов образуются каверны (полости распада) в результате слияния и распада очагов.
<br></br>
- Подострый и хронический гематогенный диссеминированный туберкулез – характеризуется очагами различного размера, часто с казеозным некрозом, которые могут с течением времени 
сливаться, образуя более крупные поражения.
<br></br>
- Лимфогенный диссеминированный туберкулез – распространение микобактерий через лимфатические пути, приводящее к формированию мелких очагов в легких и увеличению лимфатических 
узлов.
<br></br>
- Бронхогенный диссеминированный туберкулез – характеризуется распространением микобактерий через бронхи с образованием множественных очагов в нижних и средних долях легких.</p>
<p id="MsoBodyTextIndent" className="green">
Казеозная пневмония – это тяжелая форма туберкулезного поражения легких, при которой туберкулезный процесс приводит к некротическому воспалению с формированием казеозного некроза 
в легочной ткани. Этот тип воспаления является признаком высокой активности и агрессивности микобактерий туберкулеза и часто наблюдается при прогрессировании инфильтративного 
туберкулеза.
Макроскопическая картина: легкие выглядят плотными, не спадающимися при вскрытии. На разрезе ткань имеет неоднородную структуру, с участками серовато-желтого или беловатого цвета, 
которые напоминают творожистую массу (отсюда и название "казеозный"). Пораженные участки имеют мягкую, крошащуюся консистенцию, резко отличающуюся от окружающей ткани. Казеозный 
некроз может выглядеть сухим и хрупким. Области казеозной пневмонии могут варьировать в размере от небольших очагов до больших сегментов или даже долей легкого. Вокруг участков 
казеозного некроза могут наблюдаться зоны гиперемии, серозного или фибринозного воспаления, что свидетельствует о выраженной воспалительной реакции. Каверны: в некоторых случаях 
казеозная пневмония может сопровождаться образованием каверн – полостей, возникших в результате расплавления и удаления некротизированной ткани.
Характерные признаки:
Гомогенность пораженных участков: Казеозные участки однородны по структуре, без четких границ с окружающей тканью.
Воспалительная реакция: Перифокальная зона часто окружена воспалительной реакцией с инфильтрацией лимфоцитами и другими клетками иммунной системы.
Клиническая значимость: Казеозная пневмония указывает на тяжелое течение туберкулезного процесса и может быть предвестником осложнений, таких как образование каверн и 
распространение инфекции на другие участки легких и органы.</p>
<p id="MsoBodyTextIndent" className="green">
Острый кавернозный туберкулез.
Возникает при образовании полости распада, а затем каверны на месте инфильтрата или очага казеозной пневмонии. Каверна локализуется в 1 — 2 сегментах, имеет овальную или округлую 
форму, сообщается с просветом сегментарного бронха.
Внутренний слой представлен казеозными массами, наружный – уплотненной в результате воспаления альвеолярной тканью.</p>
<p id="MsoBodyTextIndent" className="green">
Фиброзно-кавернозный туберкулез, или хроническая легочная чахотка.
Возникает при хронизации острого кавернозного туберкулеза.
Стенка каверны плотная и имеет три слоя: внутренний представлен казеозными массами, средний – туберкулезной грануляционной тканью, наружный - фиброзной тканью. Внутренняя 
поверхность неровная с пересекающими полость балками, представленными облитерированными сосудами и бронхами. Процесс распространяется бронхогенно в апико-каудальном направлении, 
что приводит к появлению ацинарных и лобулярных очагов казеозной пневмонии в нижних отделах того же легкого, а также во втором легком, но позже.</p>
<p id="MsoBodyTextIndent" className="green">
Циротический туберкулез – это хроническая форма туберкулезного поражения легких, характеризующаяся выраженными фиброзными изменениями и деформацией легочной ткани в результате 
длительного течения заболевания. Он развивается как исход различных форм туберкулеза, например, инфильтративного, кавернозного или диссеминированного туберкулеза, когда в процесс 
вовлечены обширные участки легочной ткани.
Основные характеристики циротического туберкулеза:
Фиброз: обширные участки легочной ткани замещены плотной фиброзной тканью, что приводит к нарушению нормальной архитектоники легкого и уменьшению его функционального объема.
Деформация легкого: легкое становится уменьшенным в объеме, его поверхность неровная, часто втянутая. В процесс может вовлекаться плевра, что приводит к плевральным спайкам и 
утолщению.
Каверны: в некоторых случаях могут сохраняться старые каверны, окруженные толстой стенкой из фиброзной ткани.
Поражение сосудов и бронхов: фиброзное разрастание может приводить к сужению бронхов и кровеносных сосудов, что ухудшает вентиляцию и кровоснабжение легких.
Воспалительные элементы: хотя активное воспаление выражено слабо, в некоторых случаях в цирротических участках могут оставаться очаги казеозного некроза или свежие воспалительные 
инфильтраты.
Макроскопическая картина:
Легкие при циротическом туберкулезе выглядят плотными, деформированными, уменьшенными в объеме. Поверхность неровная, с наличием утолщенной плевры и спаек. На разрезе видно обилие 
плотной фиброзной ткани, в которой могут быть остатки старых каверн или участков казеозного некроза.</p>
</>

    )
}
export default TubercLungs