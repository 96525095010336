function ColotoRezVnutr(){
    return (
         <p id="MsoBodyTextIndent" className="blue"> 
            До извлечения органокомплекса проведено исследование повреждений по ходу раневого канала.  При послойном препарировании мягких тканей установлено,  что рана на 
            передней поверхности груди слева, в области левой большой грудной мышцы, продолжается в раневой канал, который имея общее направление спереди – назад, 
            снизу-вверх и слева направо (здесь и далее все направления указываются при  условии правильного вертикального положения тела с опущенными вдоль туловища руками),
             на уровне IV и III ребер по среднеключичной линии проникает в левую грудную полость, образуя здесь щелевидное повреждение вышеуказанных ребер, мышц между ними и 
             париетальной плевры, общими размерами 4,3 х 0,2 см, ориентированное большим размером на 4 – 10 часов условного циферблата. Далее раневой канал проходит через 
             верхнюю долю левого легкого: где на реберной поверхности в 1,8 см от переднего края повреждение имеет щелевидную форму, размерами 1,2 х 0,4 см, при сведении 
             краёв оно приобретает прямолинейную форму, длиной  1,5  см; на средостеночной поверхности повреждение имеет аналогичную форму, длинной при сведении краев - 1,1 
             см. Далее, проходя через перикард, раневой канал слепо заканчивается на передней (грудино-реберной) поверхности сердца, в области верхней части передней 
             продольной борозды. Здесь повреждение имеет щелевидную форму, размерами 2,4 х 0,2 см и глубиной до 0,4 см, длинник ориентирован вдоль продольной борозды. 
             В мягких тканях по ходу раневого канала, имеется кровоизлияния темно-красного цвета. Общая длина раневого канала (от раны на передней поверхности груди слева до 
             повреждения на передней поверхности сердца) составляет около 7 см.
            Для лабораторного исследования взят кожный лоскут с раной в области левой большой грудной мышцы, фрагменты III и IV ребер с повреждением из той же области.
            </p>
            
                    
    )
}
export default ColotoRezVnutr