function TubercPodj(){
    return (
        <p id="MsoBodyTextIndent" className="green">Туберкулезное поражение поджелудочной железы встречается очень редко, обычно как результат диссеминации туберкулезной инфекции 
        при генерализованных формах или распространении процесса из прилежащих органов и лимфатических узлов. Макроскопическая картина поражения поджелудочной железы при 
        туберкулезе может быть разной в зависимости от стадии заболевания и формы патологического процесса.
        Макроскопическая картина поджелудочной железы при туберкулезе:
        1. Увеличение и деформация:
        При туберкулезе поджелудочная железа может быть увеличена, особенно на отдельных участках. На ощупь она становится плотной, а её поверхность неровной и бугристой.
        В пораженных участках могут наблюдаться узловатые образования, которые соответствуют туберкулезным гранулемам или очагам казеозного некроза.
        2. Туберкулезные гранулемы:
        На поверхности железы и в её паренхиме обнаруживаются небольшие округлые серовато-белые узелки — туберкулезные гранулемы. Они могут быть видны на разрезе как мелкие очаги, окруженные реактивной воспалительной тканью.
        Гранулемы состоят из эпителиоидных клеток, лимфоцитов, а также гигантских клеток Лангханса, что можно определить при микроскопическом исследовании.
        3. Казеозный некроз:
        Внутри гранулем или отдельных участков поджелудочной железы может образовываться казеозный некроз. Макроскопически казеозные очаги выглядят как мягкие, крошащиеся беловатые массы, напоминающие творог.
        Эти очаги казеоза окружены уплотненной тканью и могут быть в виде отдельных некротических зон, либо сливаться в более крупные очаги.
        4. Фиброз:
        При хроническом течении процесса вокруг казеозных и гранулематозных очагов формируется рубцовая ткань. Это приводит к утолщению капсулы железы, формированию плотных рубцов в паренхиме, что нарушает её структуру и функцию.
        Железа становится плотной, с участками рубцовой деформации, что может приводить к частичной облитерации её протоков.
        5. Кальцификация:
        В участках казеозного некроза со временем могут откладываться кальциевые соли, и в железе появляются петрифицированные участки. Они выглядят как плотные, беловатые очаги на фоне некротической ткани.
        Петрификаты могут быть единичными или множественными и видны на разрезе железы.
        6. Признаки воспаления:
        При активном туберкулезном процессе могут наблюдаться перифокальные воспалительные изменения — отек, гиперемия и мелкие точечные кровоизлияния.
        В прилежащих тканях также могут быть признаки воспаления, что связано с реакцией на инфицирование туберкулезными микобактериями.
        </p>
    )
}
export default TubercPodj