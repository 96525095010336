function GbLab(){
    return (
        <p id="MsoBodyTextIndent" className="green"> Для ГБ высокой степени и стадии, характерно: при <u>гистологическом исследовании:</u>  Головной мозг:
        Микроинфаркты и лакунарные инфаркты: В подкорковых структурах и белом веществе мозга обнаруживаются мелкие очаги ишемического некроза. В этих зонах видны разрушение нейронов, глиоз, пролиферация астроцитов и формирование лакун.
        Сосуды мозга: Артерии и артериолы утолщены за счёт гиперплазии мышечной оболочки и фиброза стенок. Наблюдается гиалиноз стенок артериол — отложение гомогенного, стекловидного вещества в интиме сосудов, что сужает их просвет и нарушает кровоснабжение.
        Сердце:
        Гипертрофия кардиомиоцитов: Волокна кардиомиоцитов утолщены, с увеличенными ядрами (гиперхромными), что отражает гипертрофию клеток миокарда.
        Интерстициальный фиброз: Между кардиомиоцитами заметно разрастание соединительной ткани, особенно в левом желудочке. На фоне длительного высокого давления возможно развитие участков замещения миокарда фиброзной тканью.
        Сосудистые изменения: В стенках коронарных артерий видны признаки атеросклероза, включая утолщение интимы и иногда отложение кальция.
        Почки:
        Гиалиноз артериол: В стенках приносящих артериол почечных клубочков наблюдается гиалиноз — отложение гомогенного, розового материала (гиалина), который сужает просвет сосудов и ухудшает кровоснабжение почек.
        Гиперплазия и склероз сосудов: Утолщение стенок артериол и фиброз приводит к сужению просвета. В результате ухудшается фильтрация, и на поздних стадиях может развиться вторичный нефросклероз.
        Атрофия клубочков: Часть почечных клубочков склерозированы, наблюдается частичная или полная замена их фиброзной тканью. Тубулярная атрофия и интерстициальный фиброз становятся выраженными, особенно в корковом слое.
        Сосуды:
        Атеросклероз: В крупных артериях видны атеросклеротические бляшки, скапливающиеся под эндотелием. Эти бляшки состоят из липидов, коллагеновых волокон и могут содержать кальций. Вокруг них часто отмечается воспалительная реакция.
        Гиперплазия сосудистой стенки: На артериолах часто наблюдается гиперплазия клеток гладкомышечного слоя, что приводит к утолщению сосудистой стенки и сужению просвета.        
        Микроангиопатия: Утолщение стенок мелких артериол и капилляров, характерное для хронической гипертонии.
        При <u>биохимическом исследовании крови и тканей:</u> Электролиты и креатинин: Измерение уровня электролитов (натрия, калия) и креатинина в крови и тканях, поскольку гипертония может повлиять на функцию почек, что видно через изменения уровня этих веществ.
        Липидный профиль: Повышенные уровни липидов (например, холестерина) также могут свидетельствовать о сопутствующем атеросклерозе, который часто сопровождает гипертоническую болезнь.

       
        </p>                
    )
}
export default GbLab