function UshibRanaMkLab(){
    return (
        <p id="MsoBodyTextIndent" className="blue"> Направление,  постановление  и вещественные доказательства  в лабораторию доставлены нарочным в бумажном пакете и в двух прозрачных полиэтиленовых   пакетах: 
        - горловина одного из полиэтиленовых   пакетов завязана толстой белой ниткой, концы которой заклеены белой бумажной биркой с рукописным текстом: «Молоток, изъятый во время осмотра м/п … следователь (подпись) Понятые: 1(подпись) 2 (подпись)» Содержание пакета соответствует  надписи на бирке; 
        - во втором полиэтиленовом пакете находится фрагмент свода черепа с мягкими тканями и прямоугольный кусок картонки, на котором имеется рукописный текст «Костный фрагмент свода черепа из трупа N»;
        - в бумажном пакете находится кожный лоскут четырехугольной  формы, расправленный на картоне и засыпанный белым кристаллическим веществом, похожим на соль. На картоне  над кожным лоскутом имеется  рукописный текст: «№ 195 Труп N верх». Кожный лоскут  был очищен от соли и промыт в дистиллированной воде. 
        1.1. Для обнаружения следов железа в окружности раны, на представленном на исследование кожном лоскуте, была применена реакция Перса. Края раны  повреждения смачивались несколькими каплями свежеприготовленной 1:1   смеси 2% раствора желтой кровяной соли и 2% раствора соляной кислоты и через 8-10 минут  исследовались под микроскопом МБС-10. При этом по краям раны  было  сине-зеленого окрашивание в виде кольца шириной до 10 мм и наружным диаметром до 32 мм, такое окрашивание по данной методике характерно для солей железа.
        1.2. Кожный лоскут был промыт водой и высушивался в вытяжном шкафу в течение 3 суток. Затем кожный лоскут  в течение трех суток восстанавливался в спиртово-уксусном  растворе по Ратневскому. 
        1.3.  При исследовании кожного лоскута   установлено, что он имеет  четырех угольную  форму, размерами 40-х40 мм. Кожный лоскут   покрыт седыми волосами длиной до 9-10 мм. В центральной части его имеется рана пяти лучевой  звездчатой формы, общими размерами 39х19мм, с длинами лучей от 6 мм до 11 мм. Края раны неровные, в центральной части  края размозжены и осаднены в виде овала шириной до 10 мм и наружным диаметром 25-32 мм. В стенках раны под микроскопом  видны вывихнутые луковицы волос. Концы раны закруглены, некоторые - остроугольные с соединительнотканными перемычками. 
         На внутренней поверхности кожного лоскута темно-красное кровоизлияние в кожу.
        Учитывая форму и размеры раны, форму и  размеры участков осаднения, наличие размозжения ткани в центральной части ее, наличие неровных осадненых краев, тупых концов раны с соединительнотканными перемычками, вывихнутых луковиц волос в стенках раны, наличие солей железа по краям раны   следует полагать, что рана является ушибленной и причина  цилиндрическим предметом  диаметром не более 32 мм, в состав которого входит железо.
        1.4. Кости свода черепа из трупа N очищались от мягких тканей, обезжиривались, отбеливались перекисью водорода и высушивались. На исследование представлены кости свода черепа, состоящие из   фрагмента левой, правой теменных костей и фрагмента лобной кости.  В правой теменной кости, в 1,2 см от стреловидного шва и в 0,6 см от лобно-теменного шва имеется дырчатый перелом округлой формы размерами 35х37 мм с относительно ровными скошенными краями. Отмечается воронкообразный скол внутренней костной пластинки.  
        1.5. На исследование представлен молоток, состоящий из собственного молотка изготовленного из металла и деревянной рукоятки. Общая длина молотка 325мм. Собственно молоток состоит из цилиндрической формы бойка,  округлой формы центральной части (вид в профиль) с отверстием для крепления рукоятки, треугольной формы носка. На наружной поверхности собственного молотка имеются следы ржавчины. Длина рукоятки 285 мм, ширина в области крепления к молотку – 31 мм, в центральной части 34 мм и в области свободного конца – 37 мм, толщина   на всем протяжении 25 мм.  Крепление  рукоятки в молоток подвижное.  Высота молотка – 113 мм , боек в виде усеченного конуса высотой -  39 мм, диаметр верхней части бойка  -35мм,   диаметр ударной части бока  – 31 мм. Центральная часть молотка  округлая, размерами: 32х40мм, Носок треугольный, размерами: 25х20 мм. Ударная часть молотка относительно ровная. 
        <br></br>Синтезирующая часть.
        На представленном кожном лоскуте имеется ушибленная рана, причиненная   цилиндрическим предметом  диаметром   не более  32 мм, в состав которого входит железо.
        В правой теменной кости имеется дырчатый перелом округлой формы размерами 35х37 мм, причиненный цилиндрическим предметом диаметром не более 35 мм.  
        Ударная часть, представленного на исследование молотка, цилиндрической формы диаметром 31 мм.  
        Учитывая форму ушибленной раны, диаметр осадения и металлизации краев раны, диаметр дырчатого перелома и  диаметр ударной части  бойка молотка, представленного на экспертизу, следует полагать, что данные повреждения в правой теменной области  (ушибленная рана и дырчатые перелом правой теменной кости) могли быть причинены   ударной часть бойка этого  молотка.  
        </p>     
    )
}
export default UshibRanaMkLab