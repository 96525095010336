function OgnestrelOdinVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="blue">- сквозное одиночное огнестрельное пулевое ранение головы с дырчато-оскольчатыми переломами костей свода и основания черепа, повреждением головного мозга, тотальным кровоизлиянием под мягкие мозговые оболочки, кровоподтеками верхних век левого и правого глаза.
        <br></br>По поводу огнестрельного ранения головы N в 12 часов 15 минут  07.01.2017  г. поступил в больницу и умер в 13.40 того же дня (через 1 час 25 мин.).
        <br></br>Ранение головы на трупе N, является огнестрельным, что подтверждается его сквозным характером, наличием дырчатых воронкообразной формы переломов свода черепа с радиальными трещинами, наличием одной раны овальной формы с дефектом ткани, пояском осаднения, радиальными разрывами кожи, отложением копоти выстрела по ходу раневого канала  и другой раны звездчатой формы без дефекта ткани, наличием одного раневого канала в головном мозге. 
        Рана на границе  правой височной и лобной областей является входной огнестрельной раной, на что указывают её овальная форма с пояском осаднения, радиальные разрывы кожи, наличие продуктов выстрела в глубине раны и начальной части раневого канала, круглая форма дырчатого перелома в подлежащей кости с циркулярным сколом внутренней костной пластинки. 
        Рана в левой височной области является выходной огнестрельной раной, на что указывают её неправильная звездчатая форма без дефекта ткани, многоугольная форма дырчатого перелома в подлежащей кости с циркулярным сколом наружной костной пластинки.  
        Огнестрельное ранение головы причинено одним выстрелом, о чем свидетельствуют наличие одного входного и одного выходного огнестрельного повреждения, соединенных единым раневым каналом.
        Раневой канал на голове (при условии  вертикального положения головы) имеет направление справа налево, несколько спереди кзади и несколько снизу вверх, что подтверждается взаимным расположением входного и выходного повреждений. 
        Огнестрельный снаряд (пуля), причинившая данное ранение, имела диаметр ведущей части не более 1 см и оболочку, содержащую медь, о чем свидетельствует размер дырчатого перелома наружной пластинки правой половины чешуи лобной кости и наличие меди в продуктах выстрела в области входной раны. 
        Выстрел в голову был произведен в условиях упора дульного конца оружия, на что указывают признаки близкого выстрела в виде выраженного механического действия пороховых газов (радиальные разрывы кожи в области входной раны, отслойка кожи), следы воздействия дульного конца оружия в виде одной ссадины кольцевидной формы  наружным диаметром 1,8 см  и двух прилежащих ссадин дугообразной формы. 
        Доступные локализация входной огнестрельной раны и направление раневого канала, расстояние выстрела (в упор), конструктивные особенности пистолета ПМ, допускают возможность причинения огнестрельного ранения головы самим N.
        Причиной смерти N явилось сквозное одиночное огнестрельное пулевое ранение головы с повреждением головного мозга. Указанное повреждение было вредом, опасным для жизни и относится ТЯЖКОМУ вреду здоровья.
         </p>     
    )
}
export default OgnestrelOdinVyvody