function SsadinyVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="blue">- Две ссадины на тыльной поверхности левой кисти.
 <br></br>Имеющиеся у N ссадины носят травматический характер и образовались, в результате взаимодействий (какими могли быть удар, давление, сдавление, трение) с твёрдым тупым предметом (или предметами), что подтверждается самим видом повреждений.
Местом приложения травмирующей силы при образовании указанных повреждений явилась тыльная поверхность левой кисти, что подтверждается локализацией повреждений в указанной анатомической области.
Преимущественное направление действия травмирующей силы, причинившей данные повреждения, было: сзади - кпереди, что подтверждается локализацией места приложения травмирующей силы.
Стадия заживления ссадин на тыльной поверхности левой кисти (покрыты плотной, возвышающейся над уровнем окружающей кожи, местами отслаивающейся корочкой), позволяет полагать об ориентировочной давности их образования у N в срок, от 4 до 12 суток до момента его судебно-медицинского освидетельствования 26.01.2017 года.
Имеющийся у N ссадины как по отдельности, так и в совокупности не повлекли за собой кратковременного расстройства здоровья или незначительной стойкой утраты общей трудоспособности, и согласно п. 9. «Медицинских критериев определения степени тяжести вреда, причиненного здоровью человека» утвержденных приказом МЗиСР от 24 апреля 2008 года № 194н, расценивается как повреждения, не причинившие вред здоровью человека. </p>        
    )
}
export default SsadinyVyvody