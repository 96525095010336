function SaharDiab1Lab(){
    return (
        <p id="MsoBodyTextIndent" className="green">Для СД 1 типа, характерно: при <u>гистологическом исследовании:</u> 
        1. Поджелудочная железа: атрофия островков Лангерганса (заметно снижение количества бета-клеток в островках, которые обычно ответственны за выработку инсулина). 
        Оставшиеся клетки подвергаются дистрофическим изменениям. Лимфоцитарная инфильтрация: в ранних стадиях диабета 1 типа наблюдается скопление лимфоцитов вокруг островков Лангерганса (инсулярный панкреатит). Этот аутоиммунный процесс приводит к разрушению бета-клеток и последующему дефициту инсулина.
        2. Почки: диабетическая гломерулосклероз (утолщение базальной мембраны клубочков и разрастание мезангиального матрикса, что приводит к сегментарному или узелковому 
        гломерулосклерозу). Узелковые изменения часто называются узелками Киммельстила-Уилсона. Гиалиноз артериол: утолщение стенок афферентных и эфферентных артериол, 
        связанное с отложением гиалинового вещества. Это приводит к снижению кровоснабжения и нарушению функции почек. Тубулоинтерстициальные изменения: в тубулярной зоне 
        можно увидеть атрофию канальцев, интерстициальный фиброз и лимфоцитарную инфильтрацию, которые усиливаются при прогрессировании заболевания.
        3. Сосуды (микроангиопатия): утолщение базальной мембраны капилляров (в стенках капилляров, особенно в почках, сетчатке и нервах, наблюдается утолщение базальной 
        мембраны). Это изменение характерно для диабетической микроангиопатии и приводит к нарушению микроциркуляции. Гиалиноз мелких артерий: гиалиновое перерождение и 
        утолщение стенок мелких артерий, что ограничивает кровоток и вызывает ишемические повреждения тканей.
        4. Сетчатка (диабетическая ретинопатия): микроаневризмы (в капиллярах сетчатки образуются микроаневризмы — мелкие выпячивания сосудистой стенки). Кровоизлияния: на 
        разных слоях сетчатки видны точечные или пятнистые кровоизлияния, которые являются следствием повышенной ломкости сосудов. Экстравазаты и экссудаты: жировые 
        экссудаты откладываются в сетчатке из-за повышенной проницаемости сосудов. Пролиферация сосудов: в пролиферативной стадии диабетической ретинопатии происходит 
        новообразование сосудов, которые прорастают в сетчатку и стекловидное тело.
        5. Сердце: диабетическая кардиомиопатия (нередко встречается гипертрофия кардиомиоцитов, а также диффузный фиброз миокарда, вызванный хронической гипергликемией и 
        нарушением микроциркуляции). Утолщение стенок мелких коронарных сосудов: приводит к нарушению кровоснабжения миокарда, что может способствовать ишемическим 
        изменениям даже при отсутствии значительного атеросклероза.
        6. Периферические нервы (диабетическая нейропатия): демиелинизация и дегенерация аксонов (наблюдаются утрата миелина и дегенерация нервных волокон, особенно в 
        периферических нервах, что связано с ухудшением микроциркуляции и хронической гипергликемией). Эндоневральный фиброз: утолщение базальных мембран капилляров внутри 
        нервных стволов приводит к фиброзу нервной ткани и нарушению передачи нервных импульсов.
        7. Печень: умеренная жировая дистрофия (хотя жировой гепатоз встречается реже при диабете 1 типа, возможно накопление жировых капель в гепатоцитах, особенно у 
        пациентов с нестабильным течением диабета и нарушениями обмена веществ). Портальный фиброз: в портальных трактах может наблюдаться незначительный фиброз, что 
        связано с нарушением обмена углеводов и липидов.
        При <u>биохимическом исследовании крови и тканей:</u> Глюкоза в крови и тканях: Измерение уровня глюкозы в крови, спинномозговой жидкости или водянистой влаге глаза. 
        Повышенный уровень может свидетельствовать о гипергликемии перед смертью.
Гликированный гемоглобин (HbA1c): Этот показатель отражает средний уровень глюкозы в крови за последние 2–3 месяца и указывает на хроническую гипергликемию.
Кетоновые тела: Исследование уровней кетоновых тел в крови и моче (например, ацетон, бета-гидроксибутират) может указать на диабетический кетоацидоз, часто встречающийся у 
больных диабетом 1 типа.
При <u>анализе на антитела:</u> Аутоантитела к бета-клеткам (например, анти-GAD, анти-IA-2): Наличие этих антител указывает на аутоиммунный процесс, ведущий к разрушению 
бета-клеток поджелудочной железы, что характерно для диабета 1 типа.
        </p>
    )
}
export default SaharDiab1Lab