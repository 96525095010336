function ColotayaRanaVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="blue"> - Колотое слепое проникающее ранение левой теменной области головы с повреждением теменной доли головного мозга, дырчатый перелом левой теменной кости.
        <br></br>  Рана является колотой, так как имеет:
        -	неосадненные края;
        -	оба ее конца имеют разрывы;
        -	дырчатый перелом теменной кости в проекции раны имеет форму овала с ровными краями;
        -	глубина раны преобладает над ее длинной и шириной.
        Колющий предмет имел следующие свойства:
        -Формой поперечного сечения погруженной части клинка, причинившего данное повреждение, был овал, о чем свидетельствует дырчатый перелом теменной кости овальной формы.
        -Максимальный поперечный размер погруженной части клинка – 0,6 на 0,9 см, о чем свидетельствует размер дырчатого перелома теменной кости.
        -Длинна погруженной части клинка составляла около 3 см, на что указывает длинна раневого канала.
        Направление травмирующего воздействия было слева – направо, сзади – кпереди,                                                                                 
                сверху – вниз, о чем свидетельствует направление раневого канала. 
                Ранение причинено в результате одного травмирующего воздействия на что                                        
                указывает 1 рана и 1 раневой канал.
        Рана нанесена прижизненно, незадолго до смерти, о чем свидетельствуют       
               кровоизлияния в стенках раневого канала и отсутствие признаков регенерации тканей.         </p>     
    )
}
export default ColotayaRanaVyvody