function BampPerelomLab(){
    return (
        <p id="MsoBodyTextIndent" className="blue"> Исследование костных препаратов произведено в …. Участки левой и правой малоберцовых и большеберцовых костей мацерировались в теплой воде, очищались механическим путем от мягких тканей, обезжиривались в бензине, высушивались в условиях комнатной температуры и влажности и склеивались. Кости исследовались макроскопически и с помощью стереомикроскопа МССО.
            Левая большеберцовая кость. На исследование представлен фрагмент левой большеберцовой кости, размерами 14,4 х 3,5 х 2,6 см. Проксимальная плоскость отделения 
            проходит на границе между верхней и средней трети участков большеберцовой кости. Дистальная плоскость отделения проходит на границе между средней и нижней 
            трети участков большеберцовой кости. Края плоскостей отделения ровные, прямолинейные. В области медиального края кости с переходом на её заднюю и медиальную 
            поверхности имеется дефект костной ткани в виде бабочки (её крылья расположены на задней и медиальной поверхностях кости), размером 2,5 х 1,0 см. Края дефекта 
            в центре (в области медиального края кости) - ровные, без скола и выкрашивания компактного вещества. На задней и медиальной поверхностях кости (в области 
            крыльев бабочки) края дефекта приобретают мелкозазубренный вид, без скола и выкрашивания компактного вещества. От левого крыла бабочки отходят 4 линии перелома 
            на задней поверхности, а от правого крыла – 3 линии перелома на медиальной поверхности. Данные линии соединяются между собой на латеральной поверхности формируя
             осколок треугольной формы, размером 8 х 3,2 х 2,0 см. Данный осколок основанием обращен влево (к боковой поверхности), состоит из четырёх отломков, 
             многоугольной формы, размерами от 1,7 х 0,5 до 4 х 3,5 см, имеет крупнозубчатые края по линии перелома и скол компактного вещества кости (зона “сжатия”). 
             Вершина треугольника обращена вправо (к медиальному краю) с ровной мелкозубчатой линией перелома по задней и срединной поверхности большеберцовой кости, без 
             скола компактного вещества кости и дефекта костной ткани (зона “растяжения”). Указанные выше данные свидетельствуют о том, что на исследуемом фрагменте левой 
             большеберцовой кости имеется многооскольчатый косо-поперечный перелом, и травмирующая сила, причинившая описанный выше перелом кости, действовала спереди – 
             снаружи (слева) в направлении кзади – медиально (вправо).	Правая большеберцовая кость. На исследование представлен фрагмент правой большеберцовой кости, 
             размерами 13,3 х 3,0 х 2,2 см. Проксимальная плоскость отделения проходит на границе между средней и нижней трети участков большеберцовой кости. Дистальная 
             плоскость отделения проходит в нижней трети участка  большеберцовой кости. Края плоскостей отделения ровные, прямолинейные. На исследуемом фрагменте правой 
             большеберцовой кости имеются 5 дырчатых переломов, 2 из которых, в нижней части фрагмента, соединены одним раневым каналом, а 3 оставшиеся, в верхней части 
             фрагмента кости, имеют один общий разветвляющийся раневой канал. Данные переломы являются последствием фиксации костей правой голени стержневым аппаратом из 
             набора КСТ, указанной в исследовании медицинской документации. В средней части фрагмента правой большеберцовой кости располагается осколок со стороны переднего
              края, треугольной формы, размером 4,5 х 1,5 х 2,5 см, основанием обращен кпереди (к переднему краю), состоит из четырёх отломков, многоугольной формы, 
              размерами от 1,4 х 0,7 до 4,5 х 2,5 см. Данный осколок имеет крупнозубчатые края по линии перелома и скол компактного вещества кости (зона “сжатия”). Вершина 
              треугольника обращена назад (к задней поверхности) с ровной мелкозубчатой линией перелома по задней поверхности большеберцовой кости, без скола компактного 
              вещества кости и дефекта костной ткани (зона “растяжения”). Указанные выше данные свидетельствуют о том, что на исследуемом фрагменте правой большеберцовой 
              кости имеется многооскольчатый косо-поперечный перелом, и травмирующая сила, причинившая описанный выше перелом кости, действовала спереди - назад.
            </p>
                    
    )
}
export default BampPerelomLab