function BampPerelomVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="blue"> - Открытый многооскольчатый косо-поперечный перелом обеих костей левой голени в средней трети со смещением отломков и обширным кровоизлиянием в окружающие мягкие ткани; открытый многооскольчатый косо-поперечный перелом обеих костей правой голени в средней трети со смещением отломков и обширным кровоизлиянием в окружающие мягкие ткани; множественные ссадины обеих нижних конечностей.
        <br></br> Видом травмирующего воздействия, причинившим открытый многооскольчатый косо-поперечный перелом обеих костей левой голени в средней трети со смещением отломков и обширным кровоизлиянием в окружающие мягкие ткани; открытый многооскольчатый косо-поперечный перелом обеих костей правой голени в средней трети со смещением отломков и обширным кровоизлиянием в окружающие мягкие ткани, множественные ссадины обеих нижних конечностей - был удар, на что указывает вид наружных и внутренних повреждений, односторонние место приложения травмирующей силы для каждой отдельно-взятой части тела и центростремительный вектор травмирующего воздействия.
            Повреждения, обнаруженные у N, образовались в результате тупого воздействия твердых предметов, на что указывает их характер (ссадины, кровоподтеки, кровоизлияния в мягкие ткани, многооскольчатые косо-поперечные переломы). При этом, травмирующая поверхность, причинившая ссадину передне-наружной поверхности средней трети левой голени и многооскольчатые косо-поперечные переломы обеих берцовых костей в ее проекции, была ограниченной, на что указывают четкие границы ссадины, ее небольшие размеры относительно повреждаемого участка тела, а также характер перелома. Размеры травмирующей поверхности были около 9 см в ширину (высоту), на что указывают размеры ссадины.
            Местами приложения травмирующей силы, соответственно локализации наружных повреждений, подлежащих к ним повреждений мягких тканей и костей скелета, внутренних органов, были:
            - на нижних конечностях: передне-наружная поверхность средней трети левой голени и передне-внутренняя поверхность нижней трети правой голени.
            Преобладающими направлениями травмирующих воздействий, в соответствии с местами приложения травмирующих сил, взаимным расположением наружных и внутренних повреждений, морфологическими особенностями повреждений, были (при условии вертикального положения тела головой вверх):
            - на левой нижней конечности: спереди – кзади и слева – направо (множественные ссадины на передне-наружной поверхности левой голени, открытый многооскольчатый косо-поперечный перелом обеих костей левой голени);
            - на правой нижней конечности: преимущественно спереди – кзади и слева – направо (множественные ссадины на передне-внутренней поверхности правой голени, открытый многооскольчатый косо-поперечный перелом обеих костей правой голени).
            (Для всей совокупности повреждений на трупе, образовавшихся в результате автотравмы):
            Множественность повреждений, их сочетанность (т.е. локализация сразу в нескольких областях и частях тела), морфологическое разнообразие (ссадины, кровоподтеки, переломы костей скелета, разрывы и кровоизлияния в ткани внутренних органов и пр.), принадлежность при этом всех повреждений к одной группе, а именно, образующихся в результате тупого воздействия, а также одинаковая давность повреждений (непосредственно перед поступлением в больницу) указывают на возможность их образования в условиях автомобильной травмы.
            В момент первичного удара пострадавший вероятнее всего располагался к автомобилю левой передне-боковой поверхностью тела, поскольку именно здесь обнаружено больше всего повреждений, располагающихся как на уровне нижних конечностей, так и на уровне таза и груди, на это же указывает и характер переломов костей обеих голеней. Удар вероятнее всего пришелся в область передне-боковой (наружной) поверхности левой голени на высоте около 28 см от подошвенной поверхности (при условии вертикального положения тела головой вверх, и без учета высоты обуви, имевшейся на пострадавшем в момент получения травмы), на что указывает наличие здесь ссадины и открытого многооскольчатого косо-поперечного перелома обеих костей левой голени. Остальные повреждения могли образоваться в последующие фазы автомобильной травмы (при забрасывании пострадавшего на капот автомобиля, падении его на грунт и др.)
            Имевшаяся у пострадавшего тупая сочетанная травма головы, груди, живота, таза и конечностей с множественными переломами костей скелета, повреждениями внутренних органов и острой кровопотерей была опасной для жизни, обусловила наступление смерти, и поэтому относится к ТЯЖКОМУ ВРЕДУ ЗДОРОВЬЮ.
            </p>                  
    )
}
export default BampPerelomVyvody