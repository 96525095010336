function SaharDiab2Lab(){
    return (
        <p id="MsoBodyTextIndent" className="green">Для СД 2 типа, характерно: при <u>гистологическом исследовании:</u> 
        1. Поджелудочная железа: амилоидоз островков Лангерганса (одно из наиболее характерных изменений при сахарном диабете 2 типа — отложение амилоида в островках 
        Лангерганса, что приводит к снижению функции бета-клеток). Амилоидоз вызывает их прогрессивную атрофию и гибель. Гиперплазия бета-клеток: в некоторых случаях на 
        ранних стадиях заболевания наблюдается гиперплазия бета-клеток, так как организм пытается компенсировать инсулинорезистентность. Со временем клетки исчерпываются, 
        и наступает их дистрофия.
2. Почки: диабетическая нефропатия (характерные изменения включают утолщение базальной мембраны клубочков и разрастание мезангиального матрикса, что приводит к диффузному 
или узелковому гломерулосклерозу (узелки Киммельстила-Уилсона)). Гиалиноз артериол: в мелких почечных артериолах наблюдается утолщение стенок за счет отложения гиалина, что 
ухудшает кровоснабжение почек и способствует их прогрессирующей недостаточности. Тубулярная атрофия и интерстициальный фиброз: атрофия канальцев и фиброз интерстициальной 
ткани, которые усугубляются с течением времени.
3. Сосуды: микроангиопатия (утолщение базальной мембраны капилляров, особенно в почках, сетчатке и нервной системе, что нарушает микроциркуляцию). Атеросклероз: в крупных 
сосудах часто развивается выраженный атеросклероз, который может быть более агрессивным при диабете 2 типа. Атеросклеротические бляшки сужают просвет сосудов, что повышает 
риск инфаркта миокарда и инсульта. Гиалиноз мелких сосудов: Накопление гиалина в мелких артериолах приводит к их стенозу и ишемическим изменениям.
4. Сетчатка (диабетическая ретинопатия): микроаневризмы и кровоизлияния (в мелких сосудах сетчатки образуются микроаневризмы, точечные кровоизлияния и отложения экссудатов, 
что связано с повышенной ломкостью и проницаемостью сосудов). Пролиферативная ретинопатия: в более поздних стадиях могут возникать новообразованные сосуды и фиброз, которые 
повышают риск отслойки сетчатки.
5. Сердце: диабетическая кардиомиопатия (гипертрофия кардиомиоцитов и диффузный межклеточный фиброз, возникающий из-за нарушения микроциркуляции и хронической гипергликемии).
Атеросклероз коронарных артерий: прогрессирование атеросклеротического поражения крупных сосудов сердца, что может привести к ишемической болезни и инфаркту миокарда.
6. Печень: жировая дистрофия (накопление жира в гепатоцитах (стеатоз) часто связано с метаболическим синдромом и инсулинорезистентностью, что особенно характерно для 
диабета 2 типа). Фиброз: при хронической жировой дистрофии может развиться портальный фиброз, а при длительном течении — и цирроз печени.
7. Периферические нервы: диабетическая нейропатия (дегенерация аксонов, потеря миелина и эндоневральный фиброз). Эти изменения обусловлены ишемией нервной ткани и 
накоплением продуктов гликирования, что ухудшает проведение нервных импульсов и приводит к сенсорным и моторным нарушениям.
        При <u>биохимическом исследовании крови и тканей:</u> Глюкоза в крови и тканях: измерение уровня глюкозы в крови, в водянистой влаге глаза или спинномозговой жидкости. Повышенная концентрация глюкозы может указывать на гипергликемию перед смертью.
Гликированный гемоглобин (HbA1c): Этот показатель отражает уровень глюкозы в крови за последние несколько месяцев. Повышенные значения HbA1c указывают на хроническую гипергликемию, что характерно для сахарного диабета 2 типа.
Кетоновые тела: При диабете 2 типа кетоновые тела в крови или моче обычно отсутствуют или находятся на низком уровне, так как диабетический кетоацидоз для этого типа диабета менее характерен.
Липидный профиль: можно оценить уровень триглицеридов и холестерина, так как дислипидемия является частой сопутствующей патологией при диабете 2 типа.
        </p>
    )
}
export default SaharDiab2Lab