function GrippSerdce(){
    return (
        <p id="MsoBodyTextIndent" className="green">При гриппе возможны следующие изменения в сердце (эти изменения могут сопровождать миокардит, который иногда развивается при 
        тяжёлых формах гриппа): - Дистрофические изменения миокарда: миокард может выглядеть дряблым, с очагами жировой дистрофии. 
        - Расширение полостей сердца: возможны признаки сердечной недостаточности с увеличением объёма полостей. 
        - Мелкоочаговые кровоизлияния (наблюдаются как результат токсического воздействия вируса и воспалительных процессов, затрагивающих сердечную мышцу): на эпикарде и миокарде 
        можно обнаружить множественные мелкие точечные или пятнистые кровоизлияния, чаще всего тёмно-красного цвета. Они могут быть разбросаны по всей поверхности сердца, но чаще 
        встречаются в субэндокардиальных слоях и в стенках левого желудочка. Возможен общий отёк миокарда, который усиливает видимость кровоизлияний.
        </p>
    )
}
export default GrippSerdce