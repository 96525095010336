function BampPerelomOpis(){
    return (
        <p id="MsoBodyTextIndent" className="blue"> В области нижней трети правой голени, в 20 см от подошвенной поверхности стопы (далее ППС), определяется патологическая подвижность костей. Диаметр правой голени в этой области – 25 см. В области средней трети левой голени, в 28 см от подошвенной поверхности, определяется патологическая подвижность костей. Диаметр левой голени в этой области – 35 см. Левая нижняя конечность короче правой на 3,5 см.
            В области наружной поверхности левой голени, в 25 см от ППС, имеется ссадина овальной формы, размерами 9 х 6 см, более сильно выраженная в области верхнего и переднего краев, где покрыта корочкой темно-красного цвета, находящейся на уровне кожных покровов, и менее сильно в области нижнего и заднего края, где она красного цвета с поперечной (спереди – назад) исчерченностью.
            В средней трети области левой голени имеется циркулярная повязка, в центре обильно пропитанная кровью по всей окружности. При снятии  повязки, на передней поверхности голени, в 28 см от подошвенной поверхности левой стопы, в области патологической подвижности костей голени, определяется рана неправильной пятиугольной формы, размерами 4 х 4,5 см, окруженная кровоподтеком овальной формы, размерами 12 х 15 см, багрово-синюшного цвета с нечеткими границами. При сведении краев, рана приобретает углообразную форму с дефектом кожи, размером 6,5 х 1,5 см. Край левого верхнего угла раны ровный, осаднен на максимальную ширину 0,5 см, остальные края раны ровные, не осаднены. В рану выступают мягкие ткани голени и участок большеберцовой кости (рана после первичной хирургической обработки).
            Выше этой раны на 2 см, на участке 15 х 5 см, имеется множество ссадин разнообразной формы и размерами (от 0,2 х 0,5 до 4 х 2 см), покрытых корочками темно-красного цвета, находящимися ниже уровня кожных покровов.
            На передней поверхности правой голени с переходом на её внутреннюю поверхность, в 25 см от ППС, имеется, расположенная горизонтально, ссадина полосовидной формы, размером 8 х 0,5 см, покрытая корочкой темно-красного цвета, находящейся ниже уровня кожных покровов.
            На внутренней поверхности нижней трети правой голени, с переходом на переднюю поверхность, на участке 26 х 13 см, в области патологической подвижности костей голени, имеется 6 ран линейной формы, длинной 1 см, с острыми концами, ровными неосадненными краями, каждая из ран имеет 1 узловой шов ( фиксация переломов костей правой голени).  
            </p>          
    )
}
export default BampPerelomOpis