import { useSelector } from 'react-redux';

//Импорт компонентов пат изменений:
import SaharDiab1Pechen from '../../patan/saharDiab1/SaharDiab1Pechen';
import SaharDiab2Pechen from '../../patan/saharDiab2/SaharDiab2Pechen';
import VichPechen from '../../patan/vich/VichPechen';
import CovidPechen from '../../patan/covid/CovidPechen';
import GepatBPechen from '../../patan/gepatB/GepatBPechen';
import GepatCPechen from '../../patan/gepatС/GepatCPechen';
import SifilisPechen from '../../patan/sifilis/SifilisPechen';
import TubercPechen from '../../patan/tuberc/TubercPechen';

function PatanPechen() {

    //Получаем из хранилища состояния пат изменений:
    const saharDiab1 = useSelector((state) => state.pat.saharDiab1);
    const saharDiab2 = useSelector((state) => state.pat.saharDiab2);
    const vich = useSelector((state) => state.pat.vich);
    const covid = useSelector((state) => state.pat.covid);
    const gepatB = useSelector((state) => state.pat.gepatB);
    const gepatC = useSelector((state) => state.pat.gepatC);
    const sifilis = useSelector((state) => state.pat.sifilis);
    const tuberc = useSelector((state) => state.pat.tuberc);
    // const ushibRanaNoogr = useSelector((state) => state.dopPovrezhden.ushibRanaNoogr);
    

    return (
        <>
            {/* Туберкулез: */}
            {tuberc && <><TubercPechen/>
            <br></br></>}
            {/* Сахар диаб 1: */}
            {saharDiab1 && <><SaharDiab1Pechen/>
            <br></br></>}
            {/* Сахар диаб 2: */}
            {saharDiab2 && <><SaharDiab2Pechen/>
            <br></br></>}
            {/* ВИЧ: */}
            {vich && <><VichPechen/>
            <br></br></>}
            {/* Covid-19: */}
            {covid && <><CovidPechen/>
            <br></br></>}
            {/* Гепатит B: */}
            {gepatB && <><GepatBPechen/>
            <br></br></>}
            {/* Гепатит C: */}
            {gepatC && <><GepatCPechen/>
            <br></br></>}
            {/* Сифилис: */}
            {sifilis && <><SifilisPechen/>
            <br></br></>}
            {/* Ссадины: */}
            {/* {ssadiny  && <><SsadinyDiagnoz/>
            <br></br></>} */}
        </>
    );
}

export default PatanPechen;