function RezanayRanaVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="blue"> 
            - Пять резаных ран передней поверхности нижней трети левого предплечья.
 <br></br>На резаный характер ран у N указывают их линейная форма, наличие ровных краев и острых концов, преобладание длины ран над их шириной и глубиной.
Отраженные, в представленной на исследование копии истории болезни, данные о клинической картине, имеющихся у N резаных ран, возможность наложения на них швов и динамика их заживления, позволяют полагать о давности образования у него этих ран в срок, не превышающий одних суток до момента его поступления в больницу 29.08.2015 года.
Резаный характер ран указывает на то, что они причинены предметом(ами), имеющим острый край (не исключено, что лезвием ножа). Местом приложения силы являлась внутренняя поверхность нижней трети левого предплечья, что подтверждается локализацией здесь повреждений.
Клинок режущего предмета(ов), причинившего(их) данные раны, был ориентирован поперечно длиннику левого предплечья, что подтверждается поперечной ориентацией самих ран.
Имеющиеся у N резаные раны на внутренней поверхности нижней трети левого предплечья могли быть причинены в результате не менее 5 режущих движений, на что указывает количество самих ран. Направление  движения режущего предмета(ов),  причинившего(их) раны,  было слева - направо,  на что указывают наибольшая крутизна и глубина  дна всех ран.
Доступные локализация для действия собственной руки, указанный выше механизм и давность образования поверхностных, ориентированных в поперечном направлении 5  резаных ран на внутренней поверхности нижней трети левого предплечья у N, не исключают возможности их образования в период, указанный в описательной части постановления в результате самостоятельных действий N (29.08.2015 около 11 часов 00 минут, N нанес себе порезы на левом предплечье кухонным ножом, после чего был доставлен в больницу).
Поверхностные резаные раны в количестве 5 штук на внутренней поверхности нижней трети левого предплечья у N, как каждая в отдельности, так и в совокупности, повлекли за собой кратковременное расстройство здоровья и, согласно п. 8.1. «Медицинских критериев определения степени тяжести вреда, причиненного здоровью человека», утвержденных приказом МЗиСР от 24 апреля 2008 года № 194н, квалифицируются как ЛЕГКИЙ вред здоровью человека. 
 </p>             
    )
}
export default RezanayRanaVyvody