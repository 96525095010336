function OzhogiMkVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="blue"> - контактный ожог передней поверхности правой половины грудной клетки, I степени, 0,5% поверхности тела;
        - 2 контактных ожога передней поверхности левой половины брюшной стенки I-II степени, 1,7% поверхности тела;
        - контактный ожог на спине, в проекции левой лопатки, I степени, 0,8% поверхности тела;
        - 2 контактных ожога на наружной поверхности левого плеча, I-II степени, 1,2% поверхности тела;
        - контактный ожог локтевой области левой конечности, II степени, 0,1% поверхности тела;
        - контактный ожог задне-наружней поверхности предплечья левой руки, I степени, 0,1% поверхности тела.
        Общая площадь ожоговых ран составляет 4,4 % поверхности тела.
        <br></br>  Все вышеперечисленные повреждения образовались от контактных воздействий раскаленного предмета (предметов), что подтверждается: 
        - наличием у всех ожогов корочек буро-красного цвета и местами пузырей наполненных серозной жидкостью; 
        - отсутствием копоти в области всех ожоговых ран и признаков воздействия горячей жидкости в виде ожогов в форме удлиненных “языков”; 
        - отобразившейся формой контактировавшей поверхности предмета (предметов) во всех ожогах.
        Места травматических воздействий совпадают с локализацией ожогов, на что указывает их характер – контактные. 
        Количество, локализация и взаиморасположение ожоговых ран у пострадавшего свидетельствует о том, что имело место не менее 8 травматических воздействий.
        Давность образования всех ожоговых ран на момент их судебно-медицинского исследования (28 июля 20014 года) составляет от 1 до 4 суток, что подтверждается наличием у всех ран корочек буро-красного цвета, располагающихся выше уровня окружающей кожи, плотно прилежащих к поверхности раны, а также отсутствием выраженных признаков воспаления (отека и гиперемии) кожных покровов в окружности всех ран.
        Данные ожоги могли образоваться от контактного взаимодействия с подошвенной поверхностью представленного на исследование утюга (см. “Судебно-медицинская оценка результатов исследования”). Учитывая вышеизложенный механизм образования повреждений, следует считать, что восемь ожоговых ран, могли быть причинены N в период и при обстоятельствах указанных в описательной части постановления.
        Имевшиеся у N повреждения по своему характеру не являлись опасными для жизни, повлекли за собой кратковременное расстройство здоровья (ожоги 1–2 степени с общей площадью ожоговых ран 4,4% поверхности тела обычно заживают в срок менее 21 дня), и по данному признаку квалифицируются как легкий вред здоровью.        
        </p>
    )
}
export default OzhogiMkVyvody