function TubercKozha(){
    return (
        <p id="MsoBodyTextIndent" className="green">Туберкулез кожи (кожно-туберкулезные поражения) — это особая форма туберкулезного процесса, который поражает кожу и подкожную 
        клетчатку. Макроскопически кожные изменения зависят от формы заболевания, так как туберкулез кожи может проявляться в разных видах, включая люпус, скрофулодерму, язвенный 
        туберкулез и бородавчатую форму. Макроскопическая картина кожного туберкулеза:
        1. Люпус-вульгарис (волчанка обыкновенная):
        Пятна и узелки: на коже появляются красновато-коричневые узелки, часто с гладкой, слегка блестящей поверхностью. Эти узелки располагаются в дерме и могут быть различного размера.
        Цвет и консистенция: узелки имеют желтовато-коричневый цвет при надавливании (так называемый феномен яблочного желе), и каждая бляшка может быть болезненной.
        Образование язв: при прогрессировании узелки сливаются и изъязвляются, образуя поверхностные язвы с некротическими массами, которые могут быть покрыты тонкими корками. Со временем язвы расширяются и могут разрушить прилежащие ткани, в том числе хрящи.
        Рубцевание: язвы со временем заживают с образованием грубых рубцов, которые часто приводят к деформациям, особенно на лице и шее.
        2. Скрофулодерма (туберкулез периферических лимфоузлов с поражением кожи):
        Подкожные узлы: на коже над пораженными лимфатическими узлами (обычно в области шеи) появляются синюшные или багровые участки с уплотнениями и образованием узлов.
        Формирование свищей: по мере прогрессирования заболевания кожа истончается, узлы размягчаются и формируют свищи. Из свищевых ходов выделяются гнойные массы с казеозным содержимым.
        Язвы и рубцы: после вскрытия свищей остаются глубокие язвы, которые постепенно заживают, оставляя неровные и втянутые рубцы.
        3. Бородавчатый туберкулез кожи:
        Бородавчатые образования: пораженные участки кожи приобретают бородавчатый вид с неровной, плотной и грубой поверхностью. Бородавчатая ткань состоит из гиперкератотических масс.
        Цвет: пораженные участки имеют коричневый или синюшный оттенок, иногда с участками гнойного воспаления или казеоза.
        Локализация: поражения часто располагаются на открытых участках тела, которые могут подвергаться травмам (руки, ноги).
        4. Язвенный туберкулез кожи:
        Язвенные дефекты: на коже появляются глубокие язвы с подрытыми краями, некротическими массами и изъязвленной поверхностью.
        Границы язвы: края язвы плотные и немного приподняты, с неровной поверхностью, покрытой белесоватыми массами казеоза.
        Расположение: язвы могут локализоваться в области рта, носа, на слизистых оболочках и коже.
        5. Туберкулезная эритема (эритема индуратива Базена):
        Подкожные узлы: на коже голеней и реже других областей формируются болезненные, плотные узлы, которые могут сливаться и изъязвляться.
        Цвет: узлы имеют красный или багровый оттенок, со временем кожа над ними становится синюшной или бурой.
        Язвы и рубцевание: язвы заживают медленно и оставляют гиперпигментированные рубцы.
        </p>
    )
}
export default TubercKozha