function OgnestrelMnozhVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="blue"> - сквозное проникающее огнестрельное пулевое ранение груди со сквозным повреждением правого и левого желудочков сердца, обеих долей левого легкого, многооскольчатым переломом VII ребра слева, массивной кровопотерей с излитием 1,5 литров крови в левую плевральную полость;
        <br></br> - сквозное непроникающее огнестрельное пулевое ранение груди, с повреждением поверхностных мышц груди и мышц плечевого пояса слева; 
        <br></br> Оба ранения груди являются огнестрельными, что подтверждается их сквозным характером, небольшими размерами ран, отложением копоти по краям и в начальной части раневого канала каждого ранения.
        Входная огнестрельная пулевая рана сквозного проникающего ранения груди расположена на передней левой поверхности груди, в области большой грудной мышцы,  в 129 см от подошвенной поверхности стоп и в 5 см левее  передней срединной линии (рана №1).  О том, что эта рана является входной огнестрельной пулевой, указывает её округлая форма, размеры, наличие дефекта ткани, отложения копоти по краям и в окружности раны, копоть в начальной части раневого канала, ярко-красного цвета кровоизлияние в подкожно-жировую клетчатку в окружности раны.
        Выходная рана сквозного проникающего огнестрельного пулевого ранения груди расположена на спине,  в  левой лопаточной области, в 126 см от подошвенной поверхности стоп и в 6,5 см левее задней срединной линии (рана №3). О том, что эта рана является выходной, указывает её веретеновидная форма, отсутствие дефекта кожи, копоти выстрела в окружности этой раны.
        Входная огнестрельная пулевая рана  сквозного непроникающего ранения груди расположена  на передней левой поверхности груди, в области большой грудной мышцы, в 131 см от подошвенной поверхности стоп и в 12,3 см левее передней срединной линии (рана №2). О том, что эта рана является входной огнестрельной пулевой, указывает её округлая форма, размеры, наличие дефекта ткани, отложения копоти по краям и в окружности раны, копоть в начальной части раневого канала, ярко-красного цвета кровоизлияние в подкожно-жировую клетчатку в окружности раны.
        Выходная рана сквозного непроникающего огнестрельного пулевого ранения груди расположена на задней поверхности левого плечевого сустава, в 135 см от подошвенной поверхности стоп и в 16 см левее задней срединной линии (рана №4). О том, что эта рана является выходной, указывает её неправильная четырехугольная форма, отсутствие дефекта кожи, копоти выстрела в окружности этой раны.
        Отложения копоти по краям и в окружности ран №1 и №2, имеющаяся копоть в начале раневых каналов, отходящих от них, ярко-красного цвета кровоизлияния в подкожно-жировой клетчатке в окружности этих ран указывают о близкой дистанции выстрелов их причинивших.
        Входная рана №1, имеющаяся в области левой большой грудной мышцы, соединена единым раневым каналом, проходящим через мягкие ткани груди в 4-ом межреберье, левую плевральную полость, правый и левый желудочки сердца, верхнюю и нижнюю доли левого легкого, VII ребро слева, мягкие ткани спины, с выходной раной №3 в левой лопаточной области, имеющим общее направление спереди – назад, горизонтально и справа – налево.
        Входная рана №2, имеющаяся в области левой большой грудной мышцы, соединена единым раневым каналом, не проникающим в грудную полость и проходящим через мягкие ткани груди и верхнего плечевого пояса слева (поверхностные мышцы груди, мышцы плечевого пояса слева), с выходной раной №4 на задней поверхности области левого плечевого сустава, имеющим общее направление спереди - назад, снизу – вверх и справа – налево. 
        Огнестрельные ранения груди N причинены в результате двух выстрелов, о чем свидетельствует наличие двух входных и двух выходных ран, со¬единенных двумя раневыми каналами.
        При исследовании одежды установлено, что повреждения №1 и №2 на майке спереди являются входными огнестрельными повреждениями, на что указывает их характер (дефект ткани, с отходящими от него разрывами), признаки  действия пламени выстрела (краевые нити собраны в темно-серые конгломераты),  отложение на ткани вокруг них копоти выстрела.
        Повреждения  №3 и №4 на майке сзади, и №1, №2 и №3 на спинки куртки являются выходными огнестрельными повреждениями, на что указывает их форма, размеры, отсутствие дефекта ткани и копоти выстрелов. 
        Входные огнестрельные повреждения №1 и №2 на майке причинены выстрелами в пределах I зоны (преимущественного механического действия пороховых газов) близкой дистанции, на это указывают разрывы ткани в области повреждений, отложение копоти в их окружности, собранность краевых нитей в темно-серые конгломераты. Причем характерное отложение копоти в виде «бабочки» имеющееся у повреждения №2, и отсутствие на ткани в окружности повреждений целых и обгоревших зёрен пороха, при наличии признаков близкой дистанции выстрела, позволяет полагать причинение этих повреждений автоматом АК-74
        Для автомата АК-74, по литературным данным, видимые отложения копоти выстрела, в виде двух полудуг («бабочки») и разрывы ткани одежды, могут иметься при дистанции выстрела из АК-74 до 2 см. Следовательно, входные огнестрельные повреждения на майке могли быть причинены из автомата АК-74 на расстоянии не более 2 см. 
        Сквозные огнестрельные повреждения груди, имеющиеся у N, причинены огнестрельными снарядами (пулями), имеющими диаметр ведущей части около   5-7 мм, что подтверждается формой и размерами входных повреждений на трупе. Обнаружение меди в окружности и по краям входных повреждений указывает на то, что пули, причинившие их, имели оболочку в состав которой входит медь. Такими огнестрельными снарядами могли быть   5,45 мм пули к патронам для стрельбы из автомата АК-74.
        Входные и выходные повреждения на майке по своей локализации соответствуют входным и выходным ранам на трупе, а несоответствие количества выходных повреждений на куртке (3) количеству на майке и трупе (по 2), свидетельствуют о том, что пуля прошла через складку ткани на спинке куртки. 
        Направление выстрелов по отношению к груди N совпадает с направлениями имеющихся у него раневых каналов, так как ранения причинены с близкой дистанции.
        Расположение входных отверстий на ограниченной площади (расстояние между повреждениями на одежде 6,5 см, расстояние между входными ранами на трупе, расположенными в области левой большой грудной мышцы - 7,5 см), близкая ориентация направлений раневых каналов на трупе и одинаковое расстояние выстрелов с учетом наиболее сильно выраженного отложения копоти между двумя входными повреждениями на одежде (выявлено при исследовании одежды методом цветных отпечатков) указывают на возможность их причинения от автоматической очереди.
        Определить последовательность причинения огнестрельных ранений N в ходе исследования не представилось возможным, из-за отсутствия признаков по которым это можно сделать (выраженность металлизации пояска обтирания у входных ран – одинаковая, следов ружейной смазки – не обнаружено).
        Огнестрельные ранения груди, обнаруженные при судебно-медицинском исследовании трупа N, носят прижизненный характер, на что указывают кровоизлияния в области входных и выходных ран, в мягких тканях по ходу раневых каналов, 1,5 литра крови в левой плевральной полости, наружное кровотечение. Кровоподтек в области левого коленного сустава имеет также прижизненный характер, а его бордовый цвет свидетельствует о давности его причинения не более 4 дней до смерти N.
        Смерть N наступила в результате сквозного проникающего огнестрельного пулевого ранения груди со сквозным повреждением правого и левого желудочков сердца, обеих долей левого легкого, многооскольчатым переломом VII ребра слева, массивной кровопотерей с излитием 1,5 литров крови в левую плевральную полость. Данное повреждение имеет степень ТЯЖКОГО ВРЕДА здоровью, так как повлекло за собою смерть. 
        Таким образом, между полученным сквозным проникающим огнестрельным пулевым ранением груди N и наступлением его смерти имеется прямая причинная связь.
        Сквозное проникающее огнестрельное пулевого ранения груди у N - со сквозным повреждением правого и левого желудочков сердца, обеих долей левого легкого, многооскольчатым перелом VII ребра слева привело к массивной кровопотери с излитием 1,5 литров крови в левую плевральную полость, наружному кровотечению и быстрой смерти N;
        Огнестрельное сквозное непроникающее ранение груди, с повреждением поверхностных мышц груди и мышц плечевого пояса слева, не является опасным для жизни повреждением, исход его не определился в связи со смертью N, поэтому определить его степень тяжести вреда здоровью – не представляется возможным. В прямой причинной связи со смертью N оно не состоит.        
         </p>     
    )
}
export default OgnestrelMnozhVyvody