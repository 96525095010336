function TubercSpleen(){
    return (
        <p id="MsoBodyTextIndent" className="green">
        При поражении туберкулезом селезенка может подвергаться различным изменениям, в зависимости от формы заболевания и путей распространения инфекции. Макроскопические 
        изменения в селезенке при туберкулезе чаще всего наблюдаются при милиарной форме туберкулеза, но могут встречаться и при других формах, включая крупноочаговый и казеозный 
        туберкулез:
1. Милиарный туберкулез селезенки:
Внешний вид: селезенка обычно увеличена (спленомегалия), с гладкой или слегка зернистой поверхностью капсулы.
На разрезе: паренхима селезенки пропитана кровью, плотная, темно-красного или бордового цвета.
Мелкие узелки: по всему органу рассеяны многочисленные мелкие желтовато-белые бугорки (гранулемы) размером 1-2 мм, которые придают селезенке зернистый вид. Эти очаги видны на разрезе и обычно равномерно распределены.
Структура бугорков: гранулемы состоят из центрального казеозного некроза, окруженного эпителиоидными клетками и лимфоцитами. На макроскопическом уровне казеозный некроз проявляется как творожистые, слегка зернистые участки, окруженные более плотной тканью.
2. Крупноочаговый туберкулез селезенки:
Изолированные очаги: в селезенке могут быть обнаружены более крупные очаги казеозного некроза, диаметром от нескольких миллиметров до 1 см. Они напоминают одиночные узлы, беловатого или желтоватого цвета, с крошковатой консистенцией.
Фиброзное окружение: такие крупные очаги часто окружены плотной фиброзной тканью, что указывает на хроническое течение процесса. Орган может выглядеть неоднородным на разрезе.
3. Казеозный туберкулез селезенки (казеозный спленит):
Массивный казеозный некроз: на макроскопическом уровне казеозный спленит может проявляться как обширные зоны некроза в паренхиме селезенки, которые придают органу пятнистый вид.
Консистенция и цвет: пораженные участки имеют творожистую консистенцию, серо-желтого цвета, часто окружены плотной грануляционной или фиброзной тканью.
Размер и форма: некротические зоны могут быть большими и сливаться, занимая значительную часть органа.
4. Туберкулезная спленомегалия:
Общая спленомегалия: при хроническом туберкулезном процессе селезенка может быть увеличена, плотная, без видимых отдельных очагов или узелков.
Фиброз и гранулемы: при таком виде поражения селезенка может быть уплотнена за счет фиброза, особенно в хронических случаях. В глубине паренхимы можно обнаружить единичные фиброзные гранулемы.
5. Петрификация (образование петрификатов):
Остаточные изменения: при заживлении отдельных гранулем или очагов некроза в селезенке могут образовываться петрификаты — плотные кальцифицированные участки, которые видны как беловатые или серовато-белые мелкие включения на разрезе.
        </p>
    )
}
export default TubercSpleen