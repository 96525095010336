function OzhogPlamenemLab(){
    return (
        <p id="MsoBodyTextIndent" className="blue"> Результаты бактериологического исследования:
        Бактериологическое исследование было проведено в ЦКДЛ № … .
        При исследовании селезенки были выделены культуры:
        1. enterococcus faecalis
        2. staphylococcus aureus
        3. pseudomonas aeruginoza
        4. klebsiella pneumonia
        Анализ № … .  От 15.02.2016 года.        
         </p>
    )
}
export default OzhogPlamenemLab