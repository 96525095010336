function SifilisKozha(){
    return (
        <p id="MsoBodyTextIndent" className="green">При сифилисе могут быть: 
        первая стадия (первичный сифилис): основной макроскопический признак — твердый шанкр (обычно круглой или овальной формы, от нескольких миллиметров до 1–2 см в диаметре, 
        мясо-красного или розовато-красного цвета, часто с гладкой, блестящей поверхностью, края четкие, слегка приподнятые над уровнем окружающей кожи или слизистой оболочки,
основание: плотное, что и дало название шанкру («твердый»), при пальпации отмечается ощущение хрящевой плотности.
Изъязвление: может быть слабо выраженным или отсутствовать. Если язва присутствует, дно обычно чистое, покрытое серозным экссудатом или с коркой), расположенный в месте проникновения инфекции. Могут быть увеличены регионарные лимфатические узлы.
Вторая стадия (вторичный сифилис): 
сыпь на коже и слизистых оболочках. Она может быть розеолезной, папулезной или даже пустулезной, и часто поражает ладони и подошвы (сифилитическая сыпь).
Сифилитическая алопеция — выпадение волос по типу "молиеденности"(волосы выпадают в виде мелких, неравномерных очагов, напоминая поражение ткани, как если бы её поела моль).
Кондиломы широкие — образования на коже в местах повышенной влажности.
Генерализованная лимфаденопатия — увеличение лимфатических узлов. 
Третья стадия (третичный сифилис): 
1.	Сифилитические гуммы: представляют собой узловые образования, которые могут возникать в любом участке кожи или подкожной клетчатке или слизистой оболочке. Они начинаются как плотные узлы, которые затем размягчаются и могут вскрываться с образованием изъязвлений. Внешний вид: язвы, образованные вскрывшимися гуммами с серозным экссудатом, имеют характерные неровные плотные края, покрытые некротическим налетом, и склонны к заживлению с образованием рубцов. Рубцы могут быть плотными, звездчатыми, деформирующими поверхность кожи.
2. Туберкулы (бугорковый сифилид):  это небольшие, плотные узелки, которые могут располагаться группами и имеют тенденцию к некрозу и изъязвлению. Внешний вид: при распаде этих узелков возникают язвы, которые заживают с образованием атрофических рубцов, часто придавая коже "запятнанный" вид с чередованием язв и рубцов.
3. Гуммозные язвы: глубокие язвы, образовавшиеся после распада крупных гумм. Внешний вид: глубокие, болезненные язвы с грязноватым налетом на дне. Заживают медленно и оставляют глубокие, втянутые рубцы.
4. Рубцы и атрофические изменения: поражения кожи после заживления гумм или туберкулов могут оставлять грубые рубцы, которые деформируют кожный покров. Внешний вид: такие рубцы часто имеют неправильную форму и могут быть пигментированными или депигментированными, контрастируя с окружающей кожей.
5. Изменение цвета кожи: часто кожа вокруг гуммозных и бугорковых высыпаний приобретает красновато-синюшный оттенок, который становится более выраженным при нарушении целостности кожи. Внешний вид: области с рубцами могут быть гипо- или гиперпигментированы, что придает коже пятнистый вид.
6. Деструктивные изменения: в тяжелых случаях гуммозные поражения могут разрушать не только кожу, но и подлежащие ткани, включая мышцы и кости, что приводит к значительным дефектам тканей и даже обезображиванию.
Латентный сифилис: отсутствие видимых макроскопических изменений, органические поражения минимальны или скрыты, в периоды обострений – на коже может появляться сифилитическая сыпь.    
        </p>
    )
}
export default SifilisKozha