function TubercGm(){
    return (
        <>
        <p id="MsoBodyTextIndent" className="green"> При туберкулезном поражении мозга и его оболочек макроскопически выявляются признаки туберкулезного менингита и, в более редких 
        случаях, туберкулезных гранулем или абсцессов (туберкулом). Наиболее типичные изменения связаны с оболочками мозга, особенно с мягкой мозговой оболочкой, в которой 
        локализуется инфекционный процесс:
        1. Мягкая мозговая оболочка:
        Помутнение и утолщение: обычно мягкая мозговая оболочка выглядит прозрачной, но при туберкулезном менингите она становится мутной, отечной и утолщенной, особенно в области основания мозга.
        Гранулемы и туберкулезные бугорки: характерная особенность туберкулезного поражения оболочек мозга — мелкие желтовато-белые бугорки (туберкулезные гранулемы), расположенные вдоль сосудов мягкой мозговой оболочки. Наибольшая их концентрация наблюдается в области основания мозга, где они могут срастаться в крупные очаги.
        Фиброзные изменения: оболочки могут иметь участки фиброза, что приводит к их уплотнению и уменьшению подвижности.
        2. Подпаутинное пространство: 
        Помутнение ликвора: при вскрытии видно, что спинномозговая жидкость мутная, с желтоватым оттенком, иногда с примесью гноя. Это вызвано воспалительными изменениями и продуктами распада тканей.
        Нарушение циркуляции ликвора: из-за воспаления и фиброза паутинной оболочки происходит сужение подпаутинных пространств, что может затруднять нормальный ток ликвора. Это способствует повышению внутричерепного давления и развитию гидроцефалии.
        3. Область основания мозга (базилярный менингит):
        Выраженные изменения: в основании мозга (особенно в области моста, ножек мозга, зрительных нервов и гипоталамуса) обычно наиболее выражено скопление туберкулезных гранулем и фиброзных утолщений.
        Гнойное воспаление: наблюдается серозно-фибринозное или гнойное воспаление, из-за которого мягкая оболочка в области основания мозга выглядит плотной, мутной, с желтоватым налетом.
        4. Сосуды мозга:
        Воспаление и тромбоз: туберкулезный процесс часто затрагивает сосуды, вызывая их воспаление (васкулит) и иногда тромбоз, что может привести к ишемическим изменениям и инфарктам мозга.
        Образование аневризм: хроническое воспаление и разрушение сосудистой стенки могут привести к формированию мелких аневризм.</p>
        <p id="MsoBodyTextIndent" className="green">
        Макроскопическая картина туберкулем (туберкулезных абсцессов) мозга:
        Туберкулемы обычно локализуются в белом веществе больших полушарий, реже в мозжечке и стволе мозга. Чаще всего они одиночные, но могут встречаться и множественные узелковые образования.
        Внешний вид:
        туберкулема выглядит как округлое или овальное узелковое образование, которое может достигать нескольких сантиметров в диаметре.
        На разрезе она имеет плотную фиброзную капсулу, внутри которой располагается казеозный некроз, представляющий собой сухую, крошащуюся, беловатую массу. Иногда центральная часть может быть более мягкой или даже жидкой, напоминая содержимое абсцесса.
        Фиброзная капсула:
        капсула туберкулемы плотная, серовато-белая, образуется из-за реакции мозга на инфекцию и ограничивает распространение некротических масс. Со временем капсула может становиться толще и кальцифицироваться.
        </p> 
        </>               
    )
}
export default TubercGm