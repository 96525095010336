function OgnestrelOdinOpis(){
    return (
        <p id="MsoBodyTextIndent" className="blue"> На верхних веках правого и левого глаз имеются красновато-синюшные кровоподтеки размерами 2х1 см и 1х0,5 см 
        соответственно. На границе правой височной и лобной области  в 156 см от подошвенной поверхности стоп на уровне верхней части завитка правой ушной раковины и в 
        6 см кпереди от него имеется рана овальной формы размерами 0,8х1,0 см с мелконеровными краями и тремя радиальными разрывами длиной 1 см, 07 см, 06 см. Общие 
        размеры раны 3х1,8 см. При сведении краев раны в центре определяется дефект ткани овальной формы размерами 0,8х0,9 см, по краям поясок шириной 0,1 см 
        темно-коричневого цвета. Вокруг раны имеется кольцевидная коричневая ссадина шириной от 0,1 до 0,2 см и  наружным диаметром 1,8 см. В 0,2 книзу от указанной 
        кольцевидной ссадины одна под другой расположены 2 дугообразные ссадины шириной до 0.2 см и длиной по 1 см. В дне раны определяется покрытое серым налетом мозговое 
        вещество с кровоизлияниями.  В левой височной области в 160 см от подошвенной поверхности стоп, в 4 см кверху от завитка левой ушной раковины и 3 см кпереди 
        расположена рана неправильной звездчатой формы размерами 1,5х1,2 см с пятью лучами длиной от 0,4 см до 1 см. Края раны кровоподтечные, относительно ровные и 
        несколько вывернутыми наружу. При сведении краев раны дефекта ткани не определяется. В глубине раны видны мелкие костные отломки и фрагменты мозгового вещества с 
        кровоизлияниями.
         </p>     
    )
}
export default OgnestrelOdinOpis