function VichKozha(){
    return (
        <p id="MsoBodyTextIndent" className="green">Для ВИЧ клинической стадии (периода) IV (СПИД), характерно: множественные папулезные или узелковые высыпания и пятна, 
        иногда связанные с саркомой Капоши (Красновато-синие, розовые, пурпурные или коричневые, могут быть плоскими или приподнятыми. Эти высыпания обычно появляются на 
        ногах, лице, руках и туловище. Пятна могут быть небольшими (в диаметре от нескольких миллиметров до сантиметра) или более крупными узелками. Они могут сливаться 
        друг с другом, образуя более обширные поражения, в некоторых случаях может наблюдаться отек вокруг опухолевых образований. Поражения слизистых оболочек: в виде язв 
        или плоских опухолей). Изъязвления или эрозии, особенно в области полости рта, на гениталиях и анусе, могут быть вызваны вирусом герпеса или кандидозом. 
        Увеличение периферических лимфатических узлов (лимфаденопатия), особенно шейных, подмышечных и паховых.
Лимфатические узлы плотные на ощупь, могут иметь выраженную гиперплазию.
В более позднем периоде может наблюдаться их атрофия и замещение жировой тканью.
        </p>
    )
}
export default VichKozha