import { useSelector } from 'react-redux';

//Импорт компонентов пат изменений:
import GbSerdce from '../../patan/gb/GbSerdceAorta';
import SaharDiab1Serdce from '../../patan/saharDiab1/SaharDiab1Serdce';
import SaharDiab2Serdce from '../../patan/saharDiab2/SaharDiab2Serdce';
import CovidSerdce from '../../patan/covid/CovidSerdce';
import SifilisSerdce from '../../patan/sifilis/SifilisSerdce';
import GrippSerdce from '../../patan/gripp/GrippSerdce';
import TubercSerdce from '../../patan/tuberc/TubercSerdce';

function PatanSerdceAorta() {

    //Получаем из хранилища состояния пат изменений:
    const gb = useSelector((state) => state.pat.gb);
    const saharDiab1 = useSelector((state) => state.pat.saharDiab1);
    const saharDiab2 = useSelector((state) => state.pat.saharDiab2);
    const covid = useSelector((state) => state.pat.covid);
    const sifilis = useSelector((state) => state.pat.sifilis);
    const gripp = useSelector((state) => state.pat.gripp);
    const tuberc = useSelector((state) => state.pat.tuberc);
    // const ushibRanaNoogr = useSelector((state) => state.dopPovrezhden.ushibRanaNoogr);
    

    return (
        <>
            {/* Туберкулез: */}
            {tuberc && <><TubercSerdce/>
            <br></br></>}
            {/* ГБ: */}
            {gb && <><GbSerdce/>
            <br></br></>}
            {/* Сахар диаб 1: */}
            {saharDiab1 && <><SaharDiab1Serdce/>
            <br></br></>}
            {/* Сахар диаб 2: */}
            {saharDiab2 && <><SaharDiab2Serdce/>
            <br></br></>}
            {/* Covid-19: */}
            {covid && <><CovidSerdce/>
            <br></br></>}
            {/* Сифилис: */}
            {sifilis && <><SifilisSerdce/>
            <br></br></>}
            {/* Грипп: */}
            {gripp && <><GrippSerdce/>
            <br></br></>}
            {/* Ссадины: */}
            {/* {ssadiny  && <><SsadinyDiagnoz/>
            <br></br></>} */}
        </>
    );
}

export default PatanSerdceAorta;