function SaharDiab1Pochki(){
    return (
        <p id="MsoBodyTextIndent" className="green">При СД 1 типа в почках выраженная диабетическая нефропатия. 
        Размеры и масса: гипертрофия и увеличение массы почек на ранних стадиях заболевания, на поздних стадиях почки уменьшаются в размерах и массе из-за склеротических 
        изменений и утраты функциональной ткани.
Поверхность: поверхность почек становится зернистой или мелкобугристой, что связано с развитием узлового и диффузного гломерулосклероза.
Корковое вещество: истончение коркового слоя почки, иногда с участками рубцевания. Это отражает прогрессирующую потерю функционирующих нефронов.
Цвет: почки могут иметь бледный или сероватый оттенок из-за фиброзных изменений и нарушения кровообращения.
Пирамиды и мозговое вещество: могут демонстрировать признаки атрофии, иногда с участками отложения кальция.
        </p>
    )
}
export default SaharDiab1Pochki