function TubercKosti(){
    return (
        <p id="MsoBodyTextIndent" className="green">При поражении костей и суставов туберкулезом (туберкулезный остит, остеомиелит, артрит) макроскопические изменения варьируют в 
        зависимости от стадии процесса и характера поражения. Туберкулез костей и суставов чаще поражает позвоночник (туберкулезный спондилит), крупные суставы, такие как 
        коленные и тазобедренные, и реже — трубчатые кости. Макроскопическая картина поражения костей и суставов
        1. Позвоночник (туберкулезный спондилит или болезнь Потта):
        Поражение тел позвонков: процесс часто начинается в передней части тел позвонков, где возникают очаги казеозного некроза. Позвонки приобретают пористую и хрупкую структуру, что может привести к компрессионным переломам.
        Казеозный некроз: на разрезе видны желтовато-белые участки казеозного некроза. Эти зоны могут сливаться, разрушая костную ткань и образуя полости с мягким, творожистым содержимым.
        Абсцессы и свищи: казеозные массы могут распространяться по ходу связок и мягких тканей, формируя паравертебральные абсцессы, которые могут прорываться через кожу, образуя свищи.
        Деформация позвоночника: при обширном разрушении позвонков возможна деформация позвоночника (например, кифоз), особенно если поражены несколько смежных позвонков.
        2. Крупные суставы (туберкулезный артрит):
        Суставная капсула и синовиальная оболочка: оболочки сустава утолщаются, становятся гиперемированными и инфильтрируются грануляционной тканью с участками казеоза.
        Синовиальная жидкость: в суставной полости может скапливаться мутная, сероватая жидкость с казеозными массами, иногда с примесью крови.
        Разрушение хряща и субхондральной кости: туберкулезный процесс постепенно распространяется на суставной хрящ и прилегающие участки кости. Хрящ истончается и разрушается, что ведет к обнажению и повреждению подлежащей кости.
        Образование костных узелков и полостей: по мере прогрессирования процесса в субхондральной кости образуются узелки с казеозным некрозом, которые могут превращаться в полости, заполненные некротическими массами.
        Анкилоз (сращение): в результате хронического воспаления и разрушения хряща может возникнуть анкилоз — сращение суставных поверхностей, что приводит к ограничению подвижности или полной неподвижности сустава.
        3. Трубчатые кости (туберкулезный остит и остеомиелит):
        Очаги в метафизах и эпифизах: поражение часто локализуется в метафизарных областях длинных трубчатых костей (например, бедренной, большеберцовой), где наблюдаются очаги казеозного некроза.
        Казеозные полости: в местах очагов некроза могут образовываться полости, заполненные казеозными массами, которые придают кости крошковатый, мягкий вид. Полости обычно окружены зоной грануляционной ткани.
        Остеолиз и секвестры: при прогрессировании туберкулезного остеомиелита часть костной ткани может разрушаться и отделяться, образуя секвестры (участки мертвых костей), которые окружены гнойным или некротическим содержимым.
        Гипертрофия и склероз: в ответ на воспаление костная ткань может гипертрофироваться, что приводит к утолщению кости. Однако, из-за казеозного распада кость может также иметь зону склероза вокруг очагов разрушения.
        4. Поражение мягких тканей вокруг костей и суставов:
        Грануляционная ткань: вокруг костных и суставных очагов формируется грануляционная ткань, которая может разрушать окружающие мышцы, связки и сухожилия, приводя к деформации и функциональным нарушениям.
        Фиброз и кальцификация: в процессе заживления казеозные массы частично замещаются фиброзной тканью, а некротизированные участки могут кальцифицироваться, образуя плотные очаги, которые можно обнаружить при пальпации.
        </p>
    )
}
export default TubercKosti