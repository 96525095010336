function MnogoOpis(){
    return (
        <p id="MsoBodyTextIndent" className="blue"> Cсадина в лобной области слева неправильной овальной формы, размерами 1,5 x 0,9 см, с темно-красными подсохшими 
        корочками, расположенными на уровне интактной кожи, мягкие ткани вокруг ссадины несколько покрасневшие, длинник ссадины горизонтально ориентирован. Аналогичные 
        ссадины: на задней поверхности мочки левого уха неправильной овальной формы, размерами 0,5 x 0,2 см; в левой височной области неправильной овальной формы, 
        размерами 2 x 1 см; на задней поверхности левой дельтовидной области (2) полосчатой формы, размерами 0,5 x 0,2 см и 2 x 0,5 см, длинники ссадин горизонтально 
        ориентированы; на передней поверхности средней трети правого плеча полосчатой формы, размерами 4,5 x 0,3 см, длинник ссадины ориентирован соответственно цифрам 5 и 
        11 условного циферблата часов; на передней поверхности нижней трети левого плеча квадратной формы, размерами 2,5 x 2,5 см; на задне-наружной поверхности в проекции 
        левого локтевого сустава (2) неправильной овальной формы, размерами 1,5 x 1 см и 3 x 1 см; на передне-наружной поверхности верхней и средней трети левого предплечья 
        полосчатой формы, размерами 9 x 1 см, длинник ссадины вертикально ориентирован; на передне-наружной поверхности средней трети левого предплечья (3) полосчатой формы, 
        размерами 2 x 0,8 см, 4 x 1 см и 3 x 0,8 см, длинники ссадин вертикально ориентированы; на задней поверхности нижней трети левого предплечья полосчатой формы, 
        размерами 2 x 0,5 см, длинник ссадины ориентирован соответственно цифрам 1 и 7 условного циферблата часов; на тыльной поверхности левой кисти (3) неправильной 
        овальной формы, размерами по 0,8 x 0,6 см; на тыльной поверхности I пальца, в проекции проксимальной фаланги, неправильной овальной формы, размерами 2 x 1,2 см; на 
        тыльной поверхности II пальца, в проекции проксимальной фаланги, неправильной овальной формы, размерами 1,5 x 0,7 см; на тыльной поверхности III пальца, в проекции 
        проксимальной фаланги, неправильной овальной формы, размерами 2 x 1 см; на тыльной поверхности IV пальца, в проекции проксимальной фаланги, неправильной овальной 
        формы, размерами 0,5 x 0,4 см; на тыльной поверхности IV пальца, в проекции средней фаланги, неправильной овальной формы, размерами 0,8 x 0.7 см; на задней 
        поверхности груди слева, в проекции V межреберья между лопаточной и задне-подмышечной линиями, (2) округлой и полосчатой формы, диаметром 0,5 см и размерами 1,5 x 
        0,4 см; на передней поверхности в проекции коленного сустава неправильной овальной формы, размерами 0,5 x 0,3 см. Кровоподтёк на нижнем веке левого глаза неправильной 
        овальной формы, размерами 3,5 x 3 см, синюшного цвета, с нечеткими контурами, подлежащие мягкие ткани отечные. Аналогичные кровоподтеки: в левой височной области 
        размерами 3 x 1,5 см, в левой дельтовидной области с переходом на задне-наружную поверхность верхней и средней трети левого плеча неправильной овальной формы, 
        размерами 26 x 15 см; на передней поверхности нижней трети левого плеча неправильной овальной формы, размерами 13 x 10 см; на наружной поверхности верхней трети 
        правого плеча размерами 3,5 x 3 см; на передне-наружной поверхности верхней и средней трети левого предплечья размерами 9 x 8 см; на наружной поверхности средней трети 
        левого предплечья размерами 10 x 9 см; на тыльной поверхности левой кисти размерами 13 x 11 см; на задней поверхности груди слева, на уровне IV межреберья между 
        лопаточной и задне-подмышечной линиями, размерами 4,5 x 4 см; на задней поверхности груди слева, на уровне V межреберья между лопаточной и задне-подмышечной линиями, 
        полосчатой формы, размерами 8 x 3,5 см, длииник кровоподтека ориентирован соответственно цифрам 2 и 8 условного циферблата часов. При осмотре ротовой полости на верхней 
        челюсти справа обнаружен перелом 8-го зуба, корень зуба сохранен, неровный, десна в данной области покрасневшая, отечная. На волосистой части головы бинтовая повязка, 
        по снятию повязки головы: рана под шестью хирургическими швами в лобно-теменной области справа, в 3,5 см кзади от передней границы роста волос и в 5 см вправо от 
        срединной линии, П-образной формы, размерами 3 x 1 x 2 см. прикрыта темно-красной сухой корочкой, из-за которой края раны рассмотреть не представляется возможным, 
        мягкие ткани вокруг раны несколько покрасневшие. </p>     
    )
}
export default MnogoOpis