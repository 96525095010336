function TubercLab(){
    return (
        <p id="MsoBodyTextIndent" className="green"> Для туберкулеза при <u>гистологическом исследовании:</u> можно обнаружить следующие характерные изменения:
1.	Гранулемы (туберкулезные бугорки):
- Центральная зона казеозного некроза — участок некротизированной ткани с аморфным, эозинофильным материалом.
- Окружение из эпителиоидных клеток — специализированные макрофаги с удлиненными ядрами.
- Гигантские многоядерные клетки Лангханса — макрофаги, содержащие множество ядер, расположенных по периферии клетки в форме подковы.
- Лимфоциты и иногда плазматические клетки на периферии гранулемы.
2.	Легкие:
- В участках воспаления могут наблюдаться альвеолярные пространства, заполненные экссудатом, содержащим макрофаги, лимфоциты и полиморфноядерные лейкоциты.
- Участки фиброза вокруг гранулем при хроническом течении.
- Возможно формирование каверн, выстланных некротическими массами и окруженных фиброзной тканью.
3.	Лимфатические узлы:
- Казеозный некроз в центре узлов с окружающими гранулематозными структурами.
- Активная пролиферация эпителиоидных клеток и лимфоцитов.
4.	Печень:
- Наличие мелких туберкулезных гранулем, иногда с казеозным некрозом в центре.
- Реактивные изменения гепатоцитов, отек и инфильтрация паренхимы лимфоцитами.
5.	Почки:
- В случаях почечного туберкулеза можно увидеть гранулематозное воспаление с казеозным некрозом в корковом или мозговом слоях почки.
- Признаки деструкции почечных канальцев с воспалительной инфильтрацией.
6.	Мозговые оболочки (при туберкулезном менингите):
- Инфильтрация мягких мозговых оболочек лимфоцитами, плазматическими клетками и макрофагами.
- Гранулемы с казеозным некрозом, особенно на основании мозга.
- Отложение фибрина и утолщение оболочек. 
7. Мочевой пузырь:
Гранулемы с казеозным некрозом: Казеозный некроз в центре гранулем, окруженный эпителиоидными клетками и гигантскими клетками Лангханса.
Язвы и воспаление слизистой: Образование язвенных дефектов на слизистой оболочке, окруженных воспалительным инфильтратом из лимфоцитов и плазматических клеток.
Фиброз: Развитие фиброзных изменений в стенке пузыря, что приводит к утолщению и деформации органа.
8. Селезенка:
Множественные мелкие гранулемы: В паренхиме селезенки обнаруживаются многочисленные туберкулезные гранулемы с казеозным некрозом в центре.
Гиперплазия лимфоидных фолликулов: Разрастание лимфоидной ткани в ответ на инфекцию.
Фиброз и кальцификация: В хронических случаях может наблюдаться фиброз вокруг гранулем и образование петрификатов.
9. Кожа (туберкулез кожи):
Туберкулезные гранулемы в дерме: Наличие казеозных гранулем с эпителиоидными клетками и гигантскими клетками Лангханса в дерме и подкожной клетчатке.
Изъязвления: Язвенные поражения кожи с некротическими массами, окруженными воспалительной реакцией.
Фиброз: В хронических очагах происходит разрастание фиброзной ткани вокруг гранулем.
10. Кости и суставы (туберкулез костей и суставов, или остеоартрит туберкулезной этиологии):
Казеозный некроз в костной ткани: Участки костной ткани подвергаются некрозу и замещаются казеозными массами.
Гранулематозное воспаление: Формирование туберкулезных гранулем в костном мозге и в околосуставных мягких тканях.
Фиброз и разрушение суставного хряща: Разрушение хрящевой ткани с последующей фиброзной реакцией и деформацией сустава.
11. Перикард (туберкулезный перикардит):
Гранулемы в перикарде: Обнаружение туберкулезных гранулем с казеозным некрозом на поверхности перикарда.
Фиброз и утолщение перикарда: Образование плотных рубцовых тканей, что может приводить к констрикции сердца.
Скопление экссудата: Выпот, содержащий фибрин и иногда казеозные массы, что приводит к спайкам и ограничению подвижности сердечной мышцы.
12. Брюшина (туберкулезный перитонит):
Множественные гранулемы на брюшине: Распространение туберкулезных гранулем по брюшной полости, часто с казеозным некрозом.
Фибринозно-гнойный экссудат: Отложение фибрина на брюшине с серозно-гнойным выпотом, иногда образуются спайки между петлями кишечника.
Фиброз и спаечный процесс: Образование рубцовой ткани вокруг гранулем и экссудата, что может приводить к нарушению функции органов брюшной полости.
13. Кишечник:
Туберкулезные язвы в слизистой оболочке: Образование глубоких язв с подрытыми краями и казеозным содержимым.
Гранулемы в подслизистом слое: Формирование туберкулезных гранулем, особенно в подслизистом слое и мышечной оболочке кишечника.
Фиброзная трансформация стенок: Рубцевание и утолщение стенок кишечника с деформацией и сужением просвета. <u>Бактериологическое исследование:</u> Исследование мазков тканей или экссудатов с окраской по Цилю-Нильсену для обнаружения кислотоустойчивых микобактерий. Посев материала на 
специальные питательные среды для выделения и идентификации Mycobacterium tuberculosis (при возможности). <u>Молекулярно-генетические исследования (ПЦР):</u> Проведение полимеразной цепной реакции (ПЦР) для обнаружения ДНК Mycobacterium tuberculosis в тканях. Это исследование 
обладает высокой специфичностью и чувствительностью, что позволяет точно подтвердить наличие возбудителя. <u>Имуногистохимическое исследование:</u> Применение антител для специфического выявления микобактерий в тканях. Это помогает подтвердить наличие возбудителя и улучшить 
        точность диагностики.  
        </p>
    )
}
export default TubercLab