function OgnestrelPyaVnutr(){
    return (
        <p id="MsoBodyTextIndent" className="blue"> Внутренняя поверхность мягких тканей головы влажная, с массивными темно-красными кровоизлияниями в окружности ран 
        височных областей и по ходу переломов свода черепа, вне кровоизлияний - светло-розового цвета. Соответственно ране №1 в правой височной области, в месте начала 
        венечного шва имеется  дефект свода черепа овальной формы, размерами 5,0 х 4,5 см с неровными краями, захватывающий участки чешуи правой височной, лобной кости 
        справа, часть большого крыла основной и часть правой теменной кости. В мягких тканях в области этого дефекта, и в глубине продолжающегося от него раневого канала, 
        имеется множество мелких костных осколков размерами от 0,4 х 0,2 см до 4,0 х 2,0 см. Фиксированный лоскутом височной мышцы у верхне-переднего края дефекта, 
        наибольший осколок (осколок лобной кости, размерами 4,0 х 2,0 см) своим внутренним дугообразным краем обращен в центр дефекта, здесь, на наружной костной пластинке, 
        его край заострен, ровный без скола и выкрашивания вещества кости, имеет наложение вещества серо-черного цвета шириной до 0,8 см, похожего на копоть; со стороны 
        внутренней костной пластинки край осколка имеет скол на ширину до 0,5 см. С аналогичным наложением вещества серо-черного цвета и сколом внутренней костной пластинки, 
        обнаружен осколок размерами 3,5 х 2,5 см в глубине раневого канала. От верхнего края дефекта начинается сквозная трещина-перелом проходящая по венечному шву на всем 
        его протяжении. От переднего края дефекта отходят две сквозные радиальные извилистые трещины чешуи лобной кости справа, которые переходят на основание черепа в 
        переднюю черепную яму и там ветвятся. От нижнего края дефекта отходит одна сквозная радиальная извилистая трещина большого крыла основной кости, которая ветвится в 
        средней черепной яме. От заднего края дефекта отходит одна сквозная радиальная извилистая трещина чешуи височной кости, которая опускается в среднюю черепную яму, 
        там ветвится образуя множество осколков и далее переходит в заднюю черепную яму.  Соответственно ране №2 в левой височной области, на чешуе лобной кости слева с 
        переходом на левую теменную, имеется овальной формы дырчато-оскольчатый перелом размерами 1,5 х 1,0 см с воронкообразным сколом наружной костной пластинки, широкой 
        стороной направленной наружу. Указанный дырчато-оскольчатый перелом соединяется с дефектом костей свода черепа, имеющимся в проекции раны №1, сквозной 
        трещиной-переломом проходящей по венечному шву на всем его протяжении, от которой берет начало еще одна сквозная трещина левой теменной кости переходящая на чешую 
        височной и далее опускаясь на основание черепа ветвящаяся в средней и задней черепных ямах. Помимо этого, от вышеописанного дырчато-оскольчатого перелома, радиально 
        отходят три сквозные извилистые трещины, которые спускаются на основание черепа в переднюю, среднюю и заднюю черепные ямы, ветвятся и соединяются между собой и с 
        ветвями трещин идущих от дефекта костей свода черепа, имеющегося в проекции раны №1, образуя там многооскольчатые переломы. Твердая мозговая оболочка не напряжена, 
        серо-синюшная, блестящая, в области дефектов костей свода черепа и отходящих от них трещин повреждена.  В синусах твердой мозговой оболочки следы темной жидкой крови. 
        Мягкие мозговые оболочки тонкие, влажные, полупрозрачные, блестящие, с массивными темно-красными кровоизлияниями над всей поверхностью мозга, наиболее интенсивными над 
        височными и теменными долями. В области основания мозга имеются множественные темно-красные сгустки крови общим объемом до 50 мл. Извилины головного мозга несколько 
        уплощены, борозды сглажены. Основание правой лобной доли на уровне нижней лобной извилины полностью разрушено на участке 7 х 10 см. Аналогичное повреждение вещества 
        мозга имеется в области основания левой лобной доли на участке 6,5 х 7,0 см, которое захватывает покрышковую и треугольную части нижней лобной извилины углубляясь до 
        уровня нижней части средней лобной извилины. В окружности вышеописанных повреждений вещество мозга пропитано кровью, помимо этого в правой лобной доле обнаружены 
        множественные мелкие костные осколки. Ткань полушарий головного мозга на разрезах несколько отечная, влажная, дряблая, полнокровная с четким рисунком белого и серого 
        вещества. Желудочки головного мозга правильной конфигурации, заполнены кровью. … Для лабораторного исследования взяты два кожных лоскута с окружности ран в правой 
        (рана №1) и левой (рана №2) височных областях.
         </p>     
    )
}
export default OgnestrelPyaVnutr