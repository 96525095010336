function PloskKostjOgrLab(){
    return (
        <p id="MsoBodyTextIndent" className="blue"> В центре чешуи затылочной  кости находится воронкообразное вдавление, с расположенными в нем один под другим, в виде 
        ступенек, осколками. Края вдавления мелкозазубренные, с выкрашиванием компактного вещества, представлены шестиугольником, размером 2,5х2,4см. Дном воронки является 
        осколок округлой формы размером 1,2х0,9см., расположенный на 0,8см. ниже уровня кости. От верхне-правого угла вдавления вправо отходит прямолинейная трещина 
        длинной 3,0 см с неровным, косо-зазубренным просветом. На внутренней поверхности кости, соответственно месту вдавления, определяется участок вспучивания костной 
        ткани округлой формы, размером 3,2х2,8см., более выраженный на стороне отхождения трещины, и менее на противоположной.
        <br></br> Судебно-медицинская оценка результатов исследования:
           Место приложения травмирующего воздействия совпадает с локализацией перелома, о чем свидетельствует его вдавленный характер.
           Травмирующее воздействие, причинившее данный перелом, характеризовалось центростремительным вектором, о чем так же свидетельствует его вдавленный характер.   
        </p>
    )
}
export default PloskKostjOgrLab