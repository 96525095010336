function OgnestrelOdinVnutr(){
    return (
        <p id="MsoBodyTextIndent" className="blue"> Внутренняя поверхность мягких тканей головы влажная, с массивными темно-красными кровоизлияниями в окружности ран 
        височных областей и по ходу переломов свода черепа, вне кровоизлияний - светло-розового цвета. Соответственно ране на границе правой височной и лобной области в 
        правой половине чешуи лобной кости в 1 см от венечного шва дырчатый перелом круглой формы диаметром 1 см с ровными краями,  в окружности которого имеются диффузные 
        наложения серо-черного цвета, похожего на копоть. Со стороны наружной костной пластинки края перелома заостренные, ровные, без скола и выкрашивания вещества кости, 
        со стороны внутренней костной пластинки – со сплошным циркулярным сколом кости на ширину до 0,3 см и общими размерами 1,3х1,3 см. Таким образом, это повреждение 
        имеет форму усеченного конуса основанием обращенным внутрь. От краев отверстия отходят две сквозные  радиальные извилистые трещины: одна идет кзади вдоль нижнего 
        края теменной кости и заканчивающаяся у правой половины лямбдовидного шва (условно обозначена № 1), другая – кпереди (условно обозначена № 2).  Трещина № 2 через 
        2 см от начала  плавно поднимается вверх, кзади и влево, пересекает сагиттальный шов, спускается на левую половину теменной кости вниз, затухая у левой половины 
        лямбдовидного шва. В  левой половине теменной кости в 2 см кзади от венечного шва и в 1 см  от чешуйчатого шва  имеется  дырчатый перелом неправильной пятиугольной 
        формы размерами 1,2х1,5 см с циркулярным сколом наружной костной пластинки шириной до 0,2 см. Длинник дырчатого перелома ориентирован соответственно 5 и 11 часам 
        условного циферблата. От углов указанного перелома радиально отходят 4 сквозных трещины. От верхнезаднего угла трещина № 3 поднимется вертикально и заканчивается, 
        соединяясь с трещиной № 2. От заднего угла трещина № 4 поднимается вверх и кзади, соединяясь с трещиной № 2. Трещины №№ 3,4 и участок трещины № 2 образуют отломок 
        неправильной треугольной формы размерами 5х7х8 см. От верхнепереднего угла трещина № 5 на протяжении 1 см идет кпереди, затем плавно закругляется и идет книзу, на 
        уровне венечного шва разветвляется: одна ветвь идет вперед по чешуе лобной кости, затем плавно поднимается и под острым углом соединяется в трещиной № 2, другая 
        ветвь спускается на основание черепа. От нижнего угла книзу отходит трещина № 6, которая делится под углом около 60 градусов на две ветви, уходящие на основание 
        черепа. На основании черепа ветви трещин № № 5, 6 проходят через чешую, пирамидку левой височной кости, многократно делятся и образуют многооскольчатые переломы 
        пирамидки и чешуи левой височной кости, левого большого крыла и тела основной кости, глазничных частей лобной кости. Оба дырчатых перелома располагаются на одной 
        прямой линии, идущей (при условии правильного вертикального расположения головы) слева направо, несколько спереди кзади и несколько снизу вверх. Твердая мозговая 
        оболочка не напряжена, серо-синюшная, блестящая, в проекции дырчатого перелома справа овальное сквозное повреждение с неровными краями размерами 1х1,2 см, в 
        проекции дырчатого перелома слева – повреждение х-образной формы размерами 1,5х1,7 см.   В синусах твердой мозговой оболочки следы темной жидкой крови. Мягкие 
        мозговые оболочки тонкие, влажные, полупрозрачные, блестящие, с массивными светло-красными кровоизлияниями над всей поверхностью мозга, наиболее интенсивными над 
        лобными долями. В передней части продольной щели мозга с переходом на основание мозга в области глазничных извилин темно-красный сгусток крови объемом 100 мл. 
        Извилины головного мозга несколько уплощены, борозды сглажены. В правой лобной доле на границе нижней и средней лобных извилин на участке 1,5х2 см вещество мозга 
        разрушено, в окружности повреждения имеются множественные темно-красные кровоизлияния на участке 3х4 см. В левой лобной доле имеется аналогичное повреждение 
        размерами 2х2 см с муфтообразным участком множественных кровоизлияний на участке 4х5 см. Фронтальным разрезом через указанные повреждения обнаружен прямолинейный  
        раневой канал шириной до 2 см, заполненный разрушенным кашицеобразным веществом мозга, пропитанным кровью. В начальной части раневого канала в правой лобной доле 
        обнаружены мелкие костные отломки.
         </p>     
    )
}
export default OgnestrelOdinVnutr