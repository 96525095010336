function CovidPechen(){
    return (
        <p id="MsoBodyTextIndent" className="green">При COVID-19 может наблюдаться увеличение размеров печени (из-за воспаления и дистрофии) с проявлениями жировой 
        дистрофии из-за этого она может иметь тускло-коричневый или желтоватый оттенок. В некоторых случаях цвет может быть более темным или пятнистым при наличии 
        застойных изменений и кровоизлияний. На поверхности печени обычно наблюдается гладкость, но при более длительном течении болезни могут появляться участки с 
        признаками фиброза. Консистенция: Печень может быть мягкой и дряблой из-за жировой инфильтрации или отёка. При наличии более тяжёлых изменений она может стать 
        плотнее в результате воспалительных процессов или фиброзных изменений. Отёчность и полнокровие: Печень может быть полнокровной, с признаками отёчности и застоя 
        крови в синусоидах. Микротромбозы: хотя макроскопически это может быть сложно увидеть, при тщательном осмотре можно обнаружить небольшие тромбы в сосудах печени.
        </p>
    )
}
export default CovidPechen