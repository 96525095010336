function GbGm(){
    return (
        <p id="MsoBodyTextIndent" className="green"> Для ГБ высокой степени и стадии, характерно: Мелкоочаговые кровоизлияния (множество лакунарных инфарктов) и/или крупные 
        ишемические инфаркты, обусловленные сосудистыми изменениями. В редких случаях — гипертензивные геморрагические инсульты, проявляющиеся массивными кровоизлияниями в 
        ткань мозга. При наличии гипертензивного кризиса или резкого скачка давления могут быть обнаружены массивные кровоизлияния (геморрагические инсульты), чаще всего в 
        подкорковых структурах или в стволе мозга. Наличие атеросклероза артерий мозга с признаками сужения просветов. Пример описания ГМ: Мозг отёчен, вес увеличен. На 
        поверхности борозды и извилины местами сглажены из-за отёка. Вены и артерии расширены. Артерии базальных отделов мозга утолщены, заметен выраженный атеросклероз и 
        сужение просвета сосудов, с наличием фиброзных бляшек. Наличие лакунарных инфарктов — мелких участков размягчения серого и белого вещества в области подкорковых 
        ядер и мозжечка.     
        </p>                
    )
}
export default GbGm