import { useSelector } from 'react-redux';

//Импорт компонентов пат изменений:
import GbVyvody from '../../patan/gb/GbVyvody';
import SaharDiab1Vyvody from '../../patan/saharDiab1/saharDiab1Vyvody';
import SaharDiab2Vyvody from '../../patan/saharDiab2/saharDiab2Vyvody';
import VichVyvody from '../../patan/vich/VichVyvody';
import CovidVyvody from '../../patan/covid/CovidVyvody';
import GepatBVyvody from '../../patan/gepatB/GepatBVyvody';
import GepatСVyvody from '../../patan/gepatС/GepatCVyvody';
import SifilisVyvody from '../../patan/sifilis/SifilisVyvody';
import GrippVyvody from '../../patan/gripp/GrippVyvody';
import TubercVyvody from '../../patan/tuberc/TubercVyvody';

function PatanVyvody() {

    //Получаем из хранилища состояния пат изменений:
    const gb = useSelector((state) => state.pat.gb);
    const saharDiab1 = useSelector((state) => state.pat.saharDiab1);
    const saharDiab2 = useSelector((state) => state.pat.saharDiab2);
    const vich = useSelector((state) => state.pat.vich);
    const covid = useSelector((state) => state.pat.covid);
    const gepatB = useSelector((state) => state.pat.gepatB);
    const gepatC = useSelector((state) => state.pat.gepatC);
    const sifilis = useSelector((state) => state.pat.sifilis);
    const gripp = useSelector((state) => state.pat.gripp);
    const tuberc = useSelector((state) => state.pat.tuberc);
    // const ushibRanaNoogr = useSelector((state) => state.dopPovrezhden.ushibRanaNoogr);
    

    return (
        <>
            {/* Туберкулез: */}
            {tuberc && <><TubercVyvody/>
            <br></br></>}
            {/* ГБ: */}
            {gb && <><GbVyvody/>
            <br></br></>}
            {/* СД 1: */}
            {saharDiab1 && <><SaharDiab1Vyvody/>
            <br></br></>}
            {/* СД 2: */}
            {saharDiab2 && <><SaharDiab2Vyvody/>
            <br></br></>}
            {/* ВИЧ: */}
            {vich && <><VichVyvody/>
            <br></br></>}
            {/* Covid-19: */}
            {covid && <><CovidVyvody/>
            <br></br></>}
            {/* Гепатит B: */}
            {gepatB && <><GepatBVyvody/>
            <br></br></>}
            {/* Гепатит C: */}
            {gepatC && <><GepatСVyvody/>
            <br></br></>}
            {/* Сифилис: */}
            {sifilis && <><SifilisVyvody/>
            <br></br></>}
            {/* Грипп: */}
            {gripp && <><GrippVyvody/>
            <br></br></>}
            {/* Ссадины: */}
            {/* {ssadiny  && <><SsadinyDiagnoz/>
            <br></br></>} */}
        </>
    );
}

export default PatanVyvody;