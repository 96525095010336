function GbSerdceAorta(){
    return (
        <p id="MsoBodyTextIndent" className="green"> Для ГБ высокой степени и стадии, характерно: Значительное увеличение сердца (гипертрофия левого желудочка), что 
        проявляется утолщением стенок левого желудочка до 2-2.5 см и более, миокард плотный на ощупь. На срезе заметна выраженная трабекулярность миокарда в области левого 
        желудочка. Вес сердца может превышать норму, достигая 400-600 г. На поздних стадиях — признаки дилатации левого желудочка и истончение миокарда. Клапаны обычно не 
        изменены, но могут быть утолщены из-за вторичных изменений. В стенках сосудов коронарных артерий выявляется выраженный атеросклероз с сужением просветов, 
        возможны участки обызвествления. Аорта: стенка аорты утолщена, ригидна. При осмотре видны атеросклеротические бляшки, местами кальцифицированные. Поверхность 
        интимы шероховатая, с участками склероза и гиалиноза. Грудной и брюшной отделы: Выраженный атеросклероз, возможны аневризматические расширения на фоне слабости 
        стенок.
        </p>                
    )
}
export default GbSerdceAorta