import { useSelector } from 'react-redux';

//Импорт компонентов пат изменений:
import TubercBrushina from '../../patan/tuberc/TubercBrushina';

function PatanBrushina() {

    //Получаем из хранилища состояния пат изменений:
    
    const tuberc = useSelector((state) => state.pat.tuberc);
    
    // const ushibRanaNoogr = useSelector((state) => state.dopPovrezhden.ushibRanaNoogr);
    
    return (
        <>
            {/* Туберкулез: */}
            {tuberc && <><TubercBrushina/>
            <br></br></>}
            {/* Ссадины: */}
            {/* {ssadiny  && <><SsadinyDiagnoz/>
            <br></br></>} */}
        </>
    );
}

export default PatanBrushina;