import { useSelector } from 'react-redux';

//Импорт компонентов пат изменений:
import GbGm from '../../patan/gb/GbGm';
import VichGm from '../../patan/vich/VichGm';
import CovidGm from '../../patan/covid/CovidGm';
import SifilisGm from '../../patan/sifilis/SifilisGm';
import GrippGm from '../../patan/gripp/GrippGm';
import TubercGm from '../../patan/tuberc/TubercGm';

function PatanGm() {

    //Получаем из хранилища состояния пат изменений:
    const gb = useSelector((state) => state.pat.gb);
    const vich = useSelector((state) => state.pat.vich);
    const covid = useSelector((state) => state.pat.covid);
    const sifilis = useSelector((state) => state.pat.sifilis);
    const gripp = useSelector((state) => state.pat.gripp);
    const tuberc = useSelector((state) => state.pat.tuberc);
    // const ushibRanaNoogr = useSelector((state) => state.dopPovrezhden.ushibRanaNoogr);
    
    return (
        <>
            {/* Туберкулез: */}
            {tuberc && <><TubercGm/>
            <br></br></>}
            {/* ГБ: */}
            {gb && <><GbGm/>
            <br></br></>}
            {/* ВИЧ: */}
            {vich && <><VichGm/>
            <br></br></>}
            {/* Covid-19: */}
            {covid && <><CovidGm/>
            <br></br></>}
            {/* Сифилис: */}
            {sifilis && <><SifilisGm/>
            <br></br></>}
            {/* Грипп: */}
            {gripp && <><GrippGm/>
            <br></br></>}
            {/* Ссадины: */}
            {/* {ssadiny  && <><SsadinyDiagnoz/>
            <br></br></>} */}
        </>
    );
}

export default PatanGm;