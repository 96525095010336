function GepatСLab(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            Для вирусного гепатита C: при <u>гистологическом исследовании:</u> Печень: стеатоз (жировая дистрофия) гепатоцитов: часто наблюдается накопление жировых капель 
            в клетках печени, особенно при хроническом течении инфекции. 
            Перипортальная и перисинусоидальная воспалительная инфильтрация: вокруг портальных трактов часто обнаруживаются лимфоциты, плазматические клетки и макрофаги. 
            Воспаление может быть умеренным, но нарастает с прогрессированием болезни. Фиброз: развивается медленно и сначала локализуется в портальных трактах. Со временем 
            фиброзные тяжи могут распространяться между портальными трактами, приводя к мостовидному фиброзу и, в конечном итоге, к циррозу. Апоптоз и некроз гепатоцитов: 
            в отдельных участках наблюдаются признаки гибели клеток, включая пикноз ядер, кариорексис и кариолизис, что связано с воздействием вируса и воспалением. 
            Фолликулярная лимфоидная гиперплазия: в некоторых случаях в портальных зонах выявляются лимфоидные фолликулы, что связано с хроническим иммунным ответом. 
            Селезенка (при портальной гипертензии): увеличение размеров фолликулов и гиперплазия лимфоидной ткани. Мелкие кровоизлияния и фиброзные изменения в строме селезенки. 
            Лимфатические узлы: гиперплазия лимфоидной ткани, связанная с повышенной антигенной стимуляцией вирусом. встречаются лимфоидные фолликулы с выраженной 
            пролиферацией лимфоцитов и плазматических клеток, что указывает на системный характер инфекции. Почки (при длительном хроническом течении): Возможно развитие 
            мембранопролиферативного гломерулонефрита, связанного с иммунокомплексами, который характеризуется утолщением базальной мембраны клубочков и пролиферацией 
            мезангиальных клеток. <u>Иммуногистохимическое исследование:</u> обнаружение антигенов вируса гепатита C (HCV) в тканях печени. Иммуногистохимия может подтвердить вирусную этиологию 
            воспалительных изменений. <u>Серологические исследования крови:</u> обнаружение антител к вирусу гепатита C (anti-HCV) в сыворотке крови. Наличие антител 
            указывает на контакт с вирусом, но не всегда свидетельствует об активной инфекции. <u>Полимеразная цепная реакция (ПЦР):</u> для выявления РНК вируса гепатита 
            C в крови или тканях. Положительный результат ПЦР подтверждает наличие активной инфекции. <u>Биохимический анализ крови:</u> Оценка уровня билирубина, АЛТ, АСТ и 
    щелочной фосфатазы. Повышенные уровни этих ферментов могут свидетельствовать о повреждении печени.
        </p>
    )
}
export default GepatСLab