function GrippGm(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При гриппе, в случае развития гриппозного энцефалита:
        может быть заметен отёк головного мозга. Полушария головного мозга увеличены в объёме, мозговые извилины сглажены, борозды сужены.
Мозговые оболочки: возможно их умеренное помутнение и отёчность, иногда с небольшими точечными кровоизлияниями.
Сосуды: увеличение кровенаполненности сосудов мягкой мозговой оболочки.
Геморрагии: наблюдаются точечные кровоизлияния на срезах мозговой ткани, особенно в области базальных ядер, таламуса и мозжечка.
Непосредственной причиной смерти возможен как ишемический инсульт в стволе головного мозга или подкорковых структур, так и геморрагический инсульт.
        </p>                
    )
}
export default GrippGm