function GrippVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            При жизни имелась гриппозная вирусная инфекция (грипп типа A) (острый ларингит, острый трахеобронхит, острая интерстициальная вирусная пневмония), на что указывают 
            макро- и микро- скопические изменения в соответствующих органах, а также результат полимеразной цепной реакции (ПЦР) из образцов легочной ткани и трахеобронхиального 
            секрета: выделен вирусный РНК, специфичный для гриппа типа A и результат иммуногистохимического исследования: образцы легких окрашены на наличие вирусных 
            антигенов гриппа типа A. Указанное заболевание в причинной связи с наступлением смерти - не состоит.
        </p>
    )
}
export default GrippVyvody