function OgnestrelPyaVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="blue"> - сквозное одиночное проникающее огнестрельное пулевое ранение головы с переломами костей свода и основания черепа, повреждением головного мозга, тотальным кровоизлиянием под мягкие мозговые оболочки, кровоподтеками век обоих глаз;
        <br></br>1. Ранение головы на трупе N, является огнестрельным, что подтверждается его сквозным характером, наличием дырчатых воронкообразной формы переломов свода черепа с радиальными трещинами, наличием одной раны с дефектом ткани, отслоенными краями и радиальными разрывами кожи, отложением копоти выстрела по краям этой раны и по ходу раневого канала  и другой раны звезчатой формы без дефекта ткани, наличием одного раневого канала соединяющего обе эти раны. 
        2. Рана в правой височной области, в 172 см от подошвенной поверхности стоп, в 9 см правее от передней срединной линии и в 6 см выше от мочки правого уха, является входной огнестрельной раной, на что указывают наличие дефекта ткани в центре, отслоенные края,  радиальные разрывы кожи, наличие продуктов выстрела по краям раны и начальной части раневого канала. 
        Рана в левой височной области, в 174 см от подошвенной поверхности стоп, в 11,5 см левее от передней срединной линии и в 8 см к верху от мочки левого уха, является выходной огнестрельной раной, на что указывают её неправильная звездчатая форма без дефекта ткани, отсутствие продуктов выстрела в окружности этой раны, овальная форма дырчатого перелома в подлежащей кости с циркулярным сколом наружной костной пластинки.
        3. Огнестрельное ранение головы причинено одним выстрелом, о чем свидетельствуют наличие одного входного и одного выходного огнестрельного повреждения, соединенных единым раневым каналом.
        4. Cквозное одиночное огнестрельное пулевое ранение головы у N носит прижизненный характер, на что указывают кровоизлияния в области входной и выходной ран, в мягких тканях по ходу раневого канала.
        5. Раневой канал на голове (при условии  вертикального положения головы) имеет направление справа налево, несколько спереди кзади и несколько снизу вверх, что подтверждается взаимным расположением входного и выходного повреждений. 
        6. Огнестрельный снаряд (пуля), причинившая данное ранение, имела диаметр ведущей части не более 1 см и оболочку, содержащую медь, о чем свидетельствует размер дырчатого перелома на чешуе левой теменной и левой лобной костей и наличие меди в продуктах выстрела в области входной раны. 
        7. Выстрел в голову был произведен в условиях упора дульного конца оружия, на что указывают следующие признаки: выраженное механическое действие пороховых газов (радиальные разрывы кожи в области входной раны, отслойка кожи), большой дефект кожи, превышающий калибр огнестрельного снаряда, наличие копоти выстрела на наружной поверхности кожи по краям входной раны и в глубине раневого канала.
        8. Доступные локализация входной огнестрельной раны и направление раневого канала, расстояние выстрела (в упор), конструктивные особенности пистолета Ярыгина, не исключаю возможности причинения огнестрельного ранения головы самим пострадавшим.
        9. Причиной смерти N явилось сквозное одиночное проникающее огнестрельное пулевое ранение головы с переломами костей свода и основания черепа, повреждением головного мозга, тотальным кровоизлиянием под мягкие мозговые оболочки, кровоподтеками век обоих глаз. Указанное повреждение было вредом, опасным для жизни и относится к ТЯЖКОМУ вреду здоровья.        
         </p>     
    )
}
export default OgnestrelPyaVyvody