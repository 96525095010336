function OzhogPlamenemDiagnoz(){
    return (
        <p id="MsoBodyTextIndent" className="blue"> Основное заболевание:
        Термическая травма. Ожог пламенем головы, груди, живота, конечностей 20%(12%)/ II-IIIб степени. Ожоговая болезнь. Период острой ожоговой токсемии 
        (по клинико-морфологическим признакам).
        Осложнения:
         Ранний ожоговый сепсис (септицемия): глубокое поражение и некроз в области ожоговой поверхности, нагноение ожоговых ран; наличие грамм- отрицательной микрофлоры 
         при посмертном бактериологическом исследовании внутренних органов; септическая селезенка (гиперплазия пульпы селезенки); дистрофические изменения ткани печени, 
         почек, сердечной мышцы; полиорганная недостаточность по клиническим данным. Анемия смешанного происхождения по клинико-морфологическим данным. Отек легких, 
         оболочек и тканей головного мозга.           
         </p>
    )
}
export default OzhogPlamenemDiagnoz