function SifilisLab(){
    return (
        <p id="MsoBodyTextIndent" className="green"> Для сифилиса при <u>гистологическом исследовании:</u> 
        1. Кожа и слизистые оболочки: плазмоцитарная инфильтрация: в дерме и подслизистом слое наблюдается значительное скопление плазматических клеток, лимфоцитов и макрофагов.
Васкулит: стенки мелких сосудов инфильтрированы лимфоцитами и плазматическими клетками, что вызывает их утолщение, иногда вплоть до облитерации.
Гумма: в центре гуммозного узла часто присутствует казеозный некроз, окруженный зоной гранулематозного воспаления, где выявляются эпителиоидные и гигантские клетки, лимфоциты и плазматические клетки.
2. Печень: гуммозный гепатит: на периферии гуммозных узлов наблюдается воспалительный инфильтрат, состоящий из лимфоцитов и плазматических клеток. В центре гуммозного узла виден некроз.
Перипортальный фиброз: хроническое воспаление и фиброз вокруг портальных трактов с участием лимфоцитов и плазматических клеток.
Неполный и узелковый фиброз: структура печени искажается из-за фиброзных узлов и участков воспалительного инфильтрата.
3. Сердечно-сосудистая система: сифилитический аортит: в стенке аорты, особенно в адвентиции, присутствует выраженный инфильтрат из плазматических клеток и лимфоцитов. Эластические волокна средней оболочки могут разрушаться и заменяться фиброзной тканью.
Облитерирующий эндартериит: инфильтрация эндотелия мелких сосудов с разрастанием соединительной ткани приводит к сужению просвета, что может способствовать формированию аневризмы.
Некроз и фиброз: в местах поражения крупные сосуды подвергаются некрозу, а затем фиброзу, что приводит к утолщению стенки сосуда.
4. Центральная нервная система: менингит: в мягких мозговых оболочках наблюдаются воспалительные изменения — инфильтрация лимфоцитами, плазматическими клетками и макрофагами.
Гуммозный энцефалит: небольшие гуммозные узлы с зоной некроза в центре могут возникать в головном мозге, окруженные лимфоплазмоцитарным инфильтратом и фиброзом.
Дегенерация аксонов и глиоз: хроническое воспаление может привести к дегенерации нервных волокон и их замещению глиозной тканью, особенно при табесе.
5. Кости и суставы: гуммозное воспаление: в костной ткани виден некротический центр гуммы, окруженный гранулематозным воспалением и фиброзной капсулой. Наблюдаются разрушение костной ткани и её замещение фиброзной тканью.
Периостит: воспаление надкостницы с инфильтрацией лимфоцитами и плазматическими клетками; в участках воспаления формируется фиброзная ткань.
6. Легкие: сифилитическая пневмония: присутствуют участки интерстициального воспаления с инфильтрацией лимфоцитами, плазматическими клетками и макрофагами. Также могут быть обнаружены мелкие гуммы с некротическим центром.
Фиброз легочной ткани: вокруг гумм и воспалительных очагов наблюдаются участки фиброза, которые искажают структуру легочной ткани.
7. Печень и селезенка у новорожденных (при врожденном сифилисе):
сифилитический гепатит: в печени обнаруживаются диффузные воспалительные инфильтраты из плазматических клеток и лимфоцитов вокруг портальных трактов.
спленомегалия: селезенка увеличена, инфильтрирована лимфоцитами и плазматическими клетками; фолликулы увеличены и разобщены фиброзной тканью.
Эти микроскопические изменения характерны для сифилиса, и особенно типичным для него является наличие плазматических клеток в зонах воспаления, фиброз и некрозные изменения. <u>Серологические тесты на сифилис:</u> Нетрепонемные тесты: РМП (реакция микропреципитации), RPR (Rapid Plasma Reagin). Эти тесты определяют антитела, которые организм 
        вырабатывает в ответ на повреждение тканей, вызванное трепонемной инфекцией. Их положительный результат требует подтверждения трепонемными тестами.
Трепонемные тесты: FTA-ABS (флуоресцентный трепонемный тест с абсорбцией), TPHA (тест пассивной гемагглютинации с трепонемами), EIA (иммуноферментный анализ на антитела к 
трепонемам). Положительные результаты трепонемных тестов подтверждают диагноз сифилиса.
При первой стадии сифилиса: неспецифические тесты (RPR, VDRL) могут быть положительными уже через 1-2 недели после появления шанкра. Специфические тесты (FTA-ABS, TPHA) становятся 
положительными через несколько недель после заражения.
При второй стадии: положительные как неспецифические, так и специфические серологические тесты. Титры антител обычно высокие.
При третьей стадии: специфические тесты остаются положительными, но неспецифические тесты могут быть отрицательными или иметь низкие титры.
При латентном сифилисе:
- Ранний латентный: серологические тесты положительные, активность может быть выше, чем при позднем латентном.
- Поздний латентный: специфические тесты остаются положительными, но титры могут быть ниже, а неспецифические тесты могут быть отрицательными. <u>ПЦР-диагностика:</u> ПЦР для идентификации ДНК Treponema pallidum в тканях или крови. Этот метод позволяет точно подтвердить наличие возбудителя сифилиса и используется 
        для установления диагноза в сомнительных случаях. ПЦР может использоваться для обнаружения ДНК Treponema pallidum и наиболее эффективно для активных стадий, в латентных 
        стадиях может быть отрицательным из-за отсутствия активности спирохет. <u>Имуногистохимическое исследование:</u> Выявление антигенов Treponema pallidum в тканях. Этот метод может использоваться для подтверждения инфекции, особенно в случаях 
        висцерального или нейросифилиса, где возбудитель может поражать внутренние органы. <u>Микроскопия срезов кожи или слизистых оболочек:</u> Окраска по Вартину-Старри: используется для выявления трепонем в тканях при микроскопии. В сочетании с 
        гистологическими признаками она помогает подтвердить диагноз.
        </p>
    )
}
export default SifilisLab