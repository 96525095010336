import { useSelector } from 'react-redux';

//Импорт компонентов пат изменений:
import SifilisKosti from '../../patan/sifilis/SifilisKosti';
import TubercKosti from '../../patan/tuberc/TubercKosti';

function PatanKosti() {

    //Получаем из хранилища состояния пат изменений:
    const sifilis = useSelector((state) => state.pat.sifilis);
    const tuberc = useSelector((state) => state.pat.tuberc);
    
    // const ushibRanaNoogr = useSelector((state) => state.dopPovrezhden.ushibRanaNoogr);
    
    return (
        <>
            {/* Туберкулез: */}
            {tuberc && <><TubercKosti/>
            <br></br></>}
            {/* Сифилис: */}
            {sifilis && <><SifilisKosti/>
            <br></br></>}
            {/* Ссадины: */}
            {/* {ssadiny  && <><SsadinyDiagnoz/>
            <br></br></>} */}
        </>
    );
}

export default PatanKosti;