import { useSelector } from 'react-redux';

//Импорт компонентов пат изменений:
import GbLab from '../../patan/gb/GbLab';
import SaharDiab1Lab from '../../patan/saharDiab1/saharDiab1Lab';
import SaharDiab2Lab from '../../patan/saharDiab2/saharDiab2Lab';
import VichLab from '../../patan/vich/VichLab';
import CovidLab from '../../patan/covid/CovidLab';
import GepatBLab from '../../patan/gepatB/GepatBLab';
import GepatСLab from '../../patan/gepatС/GepatСLab';
import SifilisLab from '../../patan/sifilis/SifilisLab';
import GrippLab from '../../patan/gripp/GrippLab';
import TubercLab from '../../patan/tuberc/TubercLab';


function PatanLab() {

    //Получаем из хранилища состояния пат изменений:
    const gb = useSelector((state) => state.pat.gb);
    const saharDiab1 = useSelector((state) => state.pat.saharDiab1);
    const saharDiab2 = useSelector((state) => state.pat.saharDiab2);
    const vich = useSelector((state) => state.pat.vich);
    const covid = useSelector((state) => state.pat.covid);
    const gepatB = useSelector((state) => state.pat.gepatB);
    const gepatC = useSelector((state) => state.pat.gepatC);
    const sifilis = useSelector((state) => state.pat.sifilis);
    const gripp = useSelector((state) => state.pat.gripp);
    const tuberc = useSelector((state) => state.pat.tuberc);
    // const ushibRanaNoogr = useSelector((state) => state.dopPovrezhden.ushibRanaNoogr);
    

    return (
        <>
            {/* Туберкулез: */}
            {tuberc && <><TubercLab/>
            <br></br></>}
            {/* ГБ: */}
            {gb && <><GbLab/>
            <br></br></>}
            {/* СД 1 типа: */}
            {saharDiab1 && <><SaharDiab1Lab/>
            <br></br></>}
            {/* СД 2 типа: */}
            {saharDiab2 && <><SaharDiab2Lab/>
            <br></br></>}
            {/* ВИЧ: */}
            {vich && <><VichLab/>
            <br></br></>}
            {/* Covid-19: */}
            {covid && <><CovidLab/>
            <br></br></>}
            {/* Гепатит B: */}
            {gepatB && <><GepatBLab/>
            <br></br></>}
            {/* Гепатит C: */}
            {gepatC && <><GepatСLab/>
            <br></br></>}
            {/* Сифилис: */}
            {sifilis && <><SifilisLab/>
            <br></br></>}
            {/* Грипп: */}
            {gripp && <><GrippLab/>
            <br></br></>}
            {/* Ссадины: */}
            {/* {ssadiny  && <><SsadinyDiagnoz/>
            <br></br></>} */}
        </>
    );
}

export default PatanLab;