function TubercSerdce(){
    return (
        <p id="MsoBodyTextIndent" className="green">Туберкулез сердца и перикарда — редкая форма туберкулезной инфекции, которая обычно развивается вторично, при распространении 
        микобактерий туберкулеза гематогенным путем или прямым переходом инфекции с прилежащих лимфатических узлов, легких, или других органов. Поражение перикарда встречается 
        чаще, чем миокарда. Макроскопическая картина при туберкулезном поражении сердца и перикарда:
        1. Перикард (туберкулезный перикардит):
        Перикардиты: при туберкулезном перикардите чаще всего наблюдается экссудативный или фибринозный перикардит, иногда может развиваться казеозный.
        Экссудат: в полости перикарда накапливается мутная, желтоватая жидкость с примесями фибрина. В тяжелых случаях жидкость может содержать хлопья казеозных масс.
        Фибринозные наложения: внутренние листки перикарда покрыты фибрином, образуя сероватые, плотные наложения. При хроническом течении наложения становятся более грубыми и могут быть связаны с подлежащей тканью.
        Казеозный некроз: в пораженных участках перикарда, особенно в местах утолщений, можно обнаружить очаги казеозного некроза, окруженные гранулематозной тканью, что является специфическим признаком туберкулеза.
        Гранулемы: на поверхности перикарда обнаруживаются характерные туберкулезные гранулемы, с эпителиоидными клетками, лимфоцитами и иногда гигантскими клетками Лангханса. Такие гранулемы часто мелкие, но могут срастаться, образуя более крупные узлы.
        Фиброз: по мере хронизации процесса на поверхности перикарда развивается плотная, рубцовая ткань, приводящая к сращению листков перикарда. Перикард утолщен, его листки спаяны между собой, формируя «панцирное сердце», которое ограничивает подвижность сердца и нарушает его функцию.
        2. Миокард (туберкулезный миокардит):
        Гранулемы: в толще миокарда могут быть найдены небольшие туберкулезные гранулемы, хотя они встречаются реже, чем в перикарде. Эти гранулемы обычно небольшие, с казеозным центром и периферией из эпителиоидных и гигантских клеток.
        Казеозные очаги: при тяжелом течении миокарда могут образовываться казеозные очаги, что приводит к некротическим участкам внутри сердечной мышцы.
        Фиброз: если воспалительный процесс длительный, очаги некроза и гранулемы замещаются фиброзной тканью, что может нарушать сократимость сердечной мышцы.
        Аневризмы: в некоторых случаях участки миокарда, пораженные казеозным некрозом, истончаются и могут формировать локальные выпячивания — аневризмы.
        3. Эндокард:
        Поражение эндокарда при туберкулезе крайне редко и, как правило, является следствием распространения инфекции из перикарда или миокарда. На эндокарде могут появляться небольшие участки фиброза и воспалительные узелки, но специфических туберкулезных изменений эндокарда обычно не наблюдается.
        </p>
    )
}
export default TubercSerdce