function VichPechen(){
    return (
        <p id="MsoBodyTextIndent" className="green">Для ВИЧ клинической стадии (периода) IV (СПИД), характерно: 
изъязвления на слизистой оболочке пищевода, желудка и кишечника, часто связанные с оппортунистическими инфекциями (например, цитомегаловирусом или кандидозом).
Увеличение мезентериальных лимфатических узлов.
Истощение и истончение стенок кишечника, вызванные мальабсорбцией и истощением пациента.
        Гепатомегалия, иногда с признаками стеатоза (жирового перерождения). Возможны множественные мелкие узелки и очаги, что может указывать на сопутствующие инфекции, 
        такие как микобактериоз или цитомегаловирусная инфекция. 
        </p>
    )
}
export default VichPechen