function TubercBrushina(){
    return (
        <p id="MsoBodyTextIndent" className="green">
        Существуют три основные формы туберкулезного перитонита:
1. Экссудативная форма:
в брюшной полости накапливается серозно-фибринозный или геморрагический экссудат, иногда мутный.
На брюшине видны многочисленные мелкие, желтоватые бугорки (гранулемы), разбросанные по поверхности.
Пораженные участки покрыты слоем фибрина, что придает поверхности неровный и матовый вид.
2. Фибринозно-спаечная форма:
образуются многочисленные спайки между петлями кишечника, сальником и другими органами.
Спайки покрыты фибрином и могут сильно ограничивать подвижность кишечника.
На поверхности брюшины также присутствуют множественные туберкулезные бугорки и узелки.
3. Казеозная форма:
казеозные очаги развиваются в тканях брюшной полости, особенно в лимфатических узлах.
Локализованные участки казеозного некроза часто окружены плотными фиброзными капсулами.
Могут наблюдаться признаки обширного воспаления с участками казеозного распада в брюшной полости.</p>
    )
}
export default TubercBrushina