function SifilisSpleen(){
    return (
        <p id="MsoBodyTextIndent" className="green">
        При врожденном сифилисе: cелезенка увеличена и плотная, из-за чего ее называют "плотной селезенкой".
Эти макроскопические изменения являются результатом хронического воспалительного процесса, вызванного бледной трепонемой, и развиваются медленно, особенно при отсутствии 
адекватного лечения. Пример макроскопического описания плотной селезенки при врожденном сифилисе: селезенка увеличена в размерах (спленомегалия), вес превышает нормальные показатели для возраста. Капсула селезенки утолщена, туго натянута, имеет серо-белый оттенок, иногда с помутнением или участками фиброзных наложений. Поверхность органа может быть гладкой или слегка зернистой.
На разрезе паренхима селезенки плотная, суховатая, серо-красного или буроватого цвета. Заметны участки выраженного фиброза, придающие селезенке своеобразный "мускатный" или "деревянистый" вид. В плотных участках ткань не расправляется, разрез сохраняет форму и не истекает кровью.
Малпигиевы тельца часто плохо различимы или атрофированы вследствие разрастания соединительной ткани. Красная пульпа выглядит компактной, с участками фиброзных тяжей и утолщенных трабекул. На фоне фиброза могут присутствовать мелкие очаги некроза или инфильтраты, имеющие желтовато-белый цвет.
Иногда отмечаются микроскопические гранулемы, видимые макроскопически как мелкие зернистые участки, что обусловлено скоплением воспалительных клеток и проявлением сифилитического процесса.
        </p>
    )
}
export default SifilisSpleen