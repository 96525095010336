function GrippLungs(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            При гриппе: в глотке, гортани, трахеи и бронхах часто  катаральное воспаление (слизистая оболочка может быть гиперемированной, с участками эрозий и покрыта вязким 
            или гнойным секретом); в просветах бронхов могут присутствовать слизисто-гнойные пробки, которые затрудняли дыхание при жизни.
       Лёгкие могут быть увеличены в объёме, плотные, с выраженной гиперемией и отёчностью. На поверхности лёгких могут быть видны множественные кровоизлияния. Возможна 
       картина "большого пестрого лёгкого" (такая картина характеризует тяжёлое поражение лёгких при гриппозной пневмонии, нередко осложнённой бактериальной инфекцией):       
Увеличение объёма: лёгкие значительно увеличены в объёме, плотные на ощупь, на поверхности бывают видны отпечатки ребер
Пёстрая окраска: поверхность лёгких имеет пёстрый вид за счёт чередования участков гиперемии (тёмно-красных или багровых) и серо-жёлтых зон, соответствующих воспалительным 
инфильтратам и отёку.
Отёк: при разрезе лёгких выделяется пенистая, серозно-геморрагическая жидкость, что свидетельствует об интенсивном отёке.
Кровоизлияния: в ткани лёгких могут быть видны множественные мелкие и крупные кровоизлияния.
Участки уплотнения: в некоторых местах ткань лёгких плотная, что связано с консолидацией при воспалении.
Острая дыхательная недостаточность возможна как непосредственная причина смерти при такой пневмонии.
        </p>
    )
}
export default GrippLungs