function BampPerelomVnutr(){
    return (
        <p id="MsoBodyTextIndent" className="blue"> Дополнительными диагностическими разрезами мягких тканей спины, верхних и нижних конечностей, с последующей препаровкой 
        мягких тканей обнажены: позвоночный столб, ребра, кости таза, верхних и нижних конечностей. При этом установлено, что грудина, рёбра, лопатки, позвоночный столб и 
        кости верхних конечностей без повреждений. В средней трети области левой голени, в проекции раны, имеющейся на её передней поверхности, в 28 см от ППС, в области 
        патологической подвижности костей голени, обширное темно-красное кровоизлияние в подкожной клетчатке и мышцах, на участке 12 х 15 см, в глубине которого 
        определяется многооскольчатый перелом большеберцовой кости и косопоперечный перелом малоберцовой кости, расположенные в 28 см от ППС. После тщательного отделения 
        мягких тканей от краев костных отломков, осколка большеберцовой кости и сопоставления отломков с осколком установлено, что осколок располагается с боковой 
        поверхности левой большеберцовой кости, треугольной формы, размером 8 х 3,2 х 2,0 см, основанием обращен влево (к боковой поверхности), состоит из четырёх отломков,
         многоугольной формы, размерами от 1,7 х 0,5 до 4 х 3,5 см. Данный осколок имеет крупнозубчатые края по линии перелома и скол компактного вещества кости (зона 
         “сжатия”). Вершина треугольника обращена вправо (к медиальному краю) с ровной мелкозубчатой линией перелома по задней и срединной поверхности большеберцовой кости,
          без скола компактного вещества кости и дефекта костной ткани (зона “растяжения”). Для дальнейшего исследования левая большеберцовая кость выпилена отступя от 
          многооскольчатого перелома и вместе с осколками изъята. На левой малоберцовой кости, по её передне-наружней поверхности проходит линия перелома в косо-поперечном 
          направлении. Линия крупнозубчатая, неровная с изгибом компактного вещества наружу (зона “сжатия”). Далее линия продолжается на задне-внутреннюю поверхность кости,
           где она - приобретает относительно ровный характер, мелкозубчатая, без скола компактного вещества (зона “растяжения”). От данной линии в сторону передне-наружней
            поверхности кости веерообразно отходят многочисленные трещины длинной от 0,3 до 1,5 см. В нижней трети области правой голени, в 20 см от ППС, в области 
            патологической подвижности костей голени, обширное темно-красное кровоизлияние в подкожной клетчатке и мышцах, на участке 8 х 10 см, в глубине которого 
            определяются многооскольчатые переломы большеберцовой и малоберцовой кости, расположенные в 20 см от ППС. После тщательного отделения мягких тканей от краев 
            костных отломков, осколка большеберцовой кости и сопоставления отломков с осколком установлено, что осколок располагается со стороны переднего края правой 
            большеберцовой кости, треугольной формы, размером 4,5 х 1,5 х 2,5 см, основанием обращен кпереди (к переднему краю), состоит из четырёх отломков, многоугольной 
            формы, размерами от 1,4 х 0,7 до 4,5 х 2,5 см. Данный осколок имеет крупнозубчатые края по линии перелома и скол компактного вещества кости (зона “сжатия”). 
            Вершина треугольника обращена назад (к задней поверхности) с ровной мелкозубчатой линией перелома по задней поверхности большеберцовой кости, без скола 
            компактного вещества кости и дефекта костной ткани (зона “растяжения”). Для дальнейшего исследования правая большеберцовая кость выпилена отступя от 
            многооскольчатого перелома и вместе с осколками изъята. При сопоставлении отломков правой малоберцовой кости, на её передней поверхности обнаруживается дефект 
            костного вещества треугольной формы, размером 3,0 х 1,6 х 1,0 см. Основание треугольника обращено кпереди (к передней поверхности кости). Костные отломки в 
            области дефекта имеют крупнозубчатые края и скол компактного вещества (зона “сжатия”). Вершина треугольника обращена назад (к задней поверхности) с ровной 
            мелкозубчатой линией перелома по задней поверхности малоберцовой кости, без скола компактного вещества кости и дефекта костной ткани (зона “растяжения”).  
        </p>
       
    )
}
export default BampPerelomVnutr