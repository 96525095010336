function OgnestrelPyaLab(){
    return (
        <p id="MsoBodyTextIndent" className="blue"> Исследование кожных лоскутов с окружности ран в правой (рана №1) и левой (рана №2) височных областях:
        При визуальном изучении исследуемых лоскутов с помощью микроскопа ОГ МЭ-ПЗ в различных режимах освещения, с различной кратностью увеличения, а также в отраженных 
        инфракрасных и ультрафиолетовых лучах технических средств «Рельеф – 4», «Таран» - только на внутренней поверхности кожного лоскута в окружности раны №1 было 
        обнаружено две частицы овальной формы черного цвета, поглощающих инфракрасное излучение (см. рис.5). Одна из частиц была извлечена и помещена на предметное стекло. 
        Попытка её сжигания над пламенем спиртовки, имела безуспешный результат. При нанесении на частицу капли раствора дифениламина в концентрированной серной кислоте, 
        окрашивание раствора не наблюдалось.	Для выявления следов меди, входящей в состав копоти выстрела, применялся метод «цветных отпечатков». С этой целью, 
        отфиксированные, промытые в воде и высушенные листы фотобумаги, размачивались в 12% растворе аммиака и эмульсионным слоем накладывались на наружную и на внутреннюю 
        поверхности каждого кожного лоскута. Объекты помещались под пресс на 5 минут, после чего, эмульсионный слой фотобумаги обрабатывался насыщенным спиртовым раствором 
        рубеановодородной кислоты. При этом на отпечатках с наружной и  внутренней поверхности кожного лоскута с раной №1, появились участки окрашивания темно-зеленого 
        цвета, размерами 2,5 х 0,8 см  и 1,6 х 0,4 см, соответственно (см. рис. 6). Такое окрашивание по данной методике характерно для следов меди, входящей в состав 
        копоти выстрела.
         </p>     
    )
}
export default OgnestrelPyaLab