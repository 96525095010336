function CovidVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            При жизни имелся COVID-19, вызванный вирусом SARS-CoV-2 (Диффузное альвеолярное повреждение в стадии пролиферации. Интерстициальная пневмония с лимфоцитарной 
                инфильтрацией и начальным фиброзом. Микротромбозы в капиллярах лёгочной ткани), на что указывают макро- и микро- скопические 
        изменения в легких, положительный результат на антитела (IgG и IgM одновременно) в крови к вышеуказанному вирусу. Указанное заболевание в причинной связи с 
        наступлением смерти - не состоит.
        </p>
    )
}
export default CovidVyvody